.table-wrap {

}

.table-responsive {
	overflow: auto;
}

.table {

	width: 100%;

	th {
		color: $primary-color;
		padding-bottom: 1.5rem;
		font-weight: normal;
	}

	td {
		padding-top: rem(15 /2);
		padding-bottom: rem(15 /2);
	}
}

.table--bookings {
	.ellipsis {
		position: relative;
		top: rem(5);
		max-width: rem(280);
	}

	@include media-breakpoint-down(md) {
		&, tbody, tr, td {
			display: block;
			width: 100%;
			text-align: left !important;
			padding: 0 !important;
		}

		thead {
			display: none;
		}

		th, td {
			margin-bottom: .5rem;
		}

		tr {
			margin-bottom: 5rem;
		}

		td {
			&:before {
				content: attr(data-label);
				margin-right: .25rem;
				color: $primary-color;
				display: block;
			}
		}

		.btn {
			margin-top: 1rem; 
		}

		.ellipsis {
			display: block;
			max-width: none;
			white-space: normal;
			overflow: auto;
			text-overflow: initial;

			position: static;
		}
	}
}

.table--wallet {
	width: auto;
	tr td:nth-of-type(2),
	tr th:nth-of-type(2) {
		padding-left: 8rem;
		padding-right: 5rem;
	}

	tr td:nth-of-type(4),
	tr th:nth-of-type(4) {
		padding-left: 5rem;
	}

	@include media-breakpoint-down(lg) {
		width: 100%;
	}
	
	@include media-breakpoint-down(md) {

		thead {
			display: none;
		}

		&, tbody, tr, td, th {
			display: block;
			width: 100%;
			text-align: left !important;
			padding: 0 !important;
		}

		th, td {
			margin-bottom: .5rem;
		}

		tr {
			margin-bottom: 2rem;
		}
	}
}

.table--planner {

	
	td, th {
		padding: 0 1rem 1rem;
		vertical-align: top;
	}

	.td--planner-group,
	.td--planner-quantity,
	.td--planner-price {
		vertical-align: middle;
		// display: table-cell !important;
	}

	.td--planner-group {
		width: 10rem;
	}

	.td--planner-price-buy,
	.td--planner-group-buy,
	.td--planner-group-buy,
	.td--planner-group {
		line-height: 1;
	}

	.td--planner-price-buy,
	.td--planner-group-buy {
		vertical-align: top;
	}
	
	@include media-breakpoint-down(md) {

		.personPicker {
			width: 30rem;
		}

		thead {
			display: none;
		}

		&, tbody, tr, td, th {
			display: block;
			width: 100% !important;
			text-align: left !important;
			padding: 0 !important;
		}

		th, td {
			margin-bottom: .5rem;
		}

		tr {
			margin-bottom: 2rem;
		}
	}

	@include media-breakpoint-down(xs) {
		.personPicker {
			width: 100%;
		}
	}
}