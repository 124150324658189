.page--detail {
	.teaser {
		.square--s10 {
			position: absolute;
			top: rem(90);
			right: rem(188);

			@include media-breakpoint-down(md) {
				position: relative;
				right: auto;
				left: 0;
				top: 0;
				height: auto;
				min-height: rem(460);

				display: flex;
				flex-direction: column;

				justify-content: space-between;

				.square__footer {
					position: static;
					width: auto;
				}
			}

			@include media-breakpoint-down(xs) {
				width: rem(277, 14);
				min-height: rem(277, 14);

				.square__title {
					font-size: rem(20, 14);
				}

			}
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 3rem;
			height: auto;

			.teaser__content {
				position: relative;
				margin-top: rem(-280);
				transform: none;
				top: 0;
				width: calc(100% - #{rem(70)});
				left: rem(35);
			}
		}

		@include media-breakpoint-down(xs) {
			.teaser__content {
				margin-top: rem(-175, 14);
			}
		}
	}

	.fixed {
		position: absolute;
		top: 0;
		right: 0;
		width: rem(260);

		@include media-breakpoint-down(md) {
			&:not(.fixed--mobile) {
				display: none;
			}
		}
	}

	.fixed--mobile {
		width: 100%;
		background: #e3fae3;

		position: fixed;
		bottom: 0;
		top: auto;
		left: 0;
		right: auto;
		width: 100%;

		height: rem(55, 14);
		z-index: 3;
	}

	.stickem-container {
		position: relative;
	}

	.stickit {
		position: fixed;
		top: rem(75);
		right: calc(50% - #{rem((1080) / 2)});

		@include media-breakpoint-down(lg) {
			right: calc(5% + #{$grid-gutter-width-half});
		}
	}

	.stickit-end {
		top: auto;
		bottom: 0;
		position: absolute;
		right: 0;
	}

	.order {
		padding: rem(10) rem(16) rem(20);
		background: #e3fae3;
	}

	.square--green-diagonale {
		display: none;
	}

	.fixed:not(.fixed--suspended) {
		.square--green-diagonale {
			@media (min-height: 575px) {
				display: block;
			}
		}
	}

	.fixed--suspended {
		.square--green-diagonale {
			@media (min-height: 740px) {
				display: block;
			}
		}
	}

	.section--recommendation {
		.container {
			padding: 0;
		}
	}

	.section--testimonials {
		.container {
			padding: 0;
		}
	}
}