.btn {
	@extend .text--small;
	border: none;
	position: relative;
	text-transform: uppercase;
	min-width: rem(130);
	height: rem(40);
	line-height: rem(40);
	padding: 0 (rem(40) + 1rem) 0 1rem;
	font-weight: 500;
	background-color: $primary-color-light;
	box-shadow: none !important;

	white-space: nowrap;

	&:before,
	&:after {
		font-family: $font-family-base !important;
		font-weight: 700 !important;
	}

	&:before {
		content: '→';
		position: absolute;
		right: 0;
		font-size: rem(18);
		font-weight: 700;
		width: rem(40);
		height: 100%;

		background: $primary-color;
		line-height: rem(37);

		transition: $transition;
	}

	&.square {
		min-width: auto;
		background-color: $primary-color;
		&:before {
			display: none;
		}
	}
}

a.btn,
input.btn,
button.btn {
	&:hover,
	&:focus {
		background-color: $secondary-color-light;
		&:before {
			background-color: $secondary-color;
		}
	}
}

.btn--back {
	padding: 0 1rem 0 (rem(40) + 1rem);
	min-width: auto;

	&:before {
		content: '←';
		right: auto;
		left: 0;
	}
}

.btn--transparent {
	background: transparent;

	&.btn--bordered {
		&:hover,
		&:focus {
			background: transparent;
			&:before {
				border-color: $secondary-color;
			}
		}
	}
}

.btn--bordered {
	&:before {
		border: 1px solid $primary-color;
		background: transparent;
	}
}

.btn--border {
	border: 1px solid $primary-color;

	&:hover,
	&:focus {
		border-color: $secondary-color;
	}
}

.btn--link {
	position: relative;
	&:after {
		content: '↘';
		font-weight: 700;
		font-size: rem(28);
		
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.square--s1 {
		&:after {
			@extend .text--semi-small;
		}
	}

	&.square--s3 {
		&:after {
			@extend .text--semi-large;
		}
	}
}

.btn--link-left {
	&:after {
		content: '←';
	}
}

.btn--link-right {
	&:after {
		content: '→';
	}
}

.btn--link-top {
	&:after {
		content: '↗';
	}
}

.btn--search {
	background-image: url(../images/icons/ico-search.svg);
	background-size: rem(21.096) rem(19.596);
	background-position: center;
	background-repeat: no-repeat;
	text-indent: -99999px;
}

.btn--profile {
	background-image: url(../images/icons/ico-profile.svg);
	background-size: rem(23.52) rem(20.99);
	background-position: center;
	background-repeat: no-repeat;
	text-indent: -99999px;
}