.list--no-style {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list {
  margin-bottom: 1.5rem;
}

.list__item {
  margin-bottom: rem(13);
}

.list--inline {
  display: flex;
}