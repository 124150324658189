.news {

}

.news__content {
	a {
		@extend .link;
		font-weight: 700;
	}

	p:last-child {
		margin-bottom: 0;
	}
}