.page--profile {

	.profile-menu-mobile,
	.profile-menu {
		background: #f1f0ef;

		position: absolute;
		top: 0;
		right: 0;
		width: rem(260);

		list-style: none;

		border-bottom: 5px solid $primary-color;

		padding: 1rem;

		a {
			&:hover, .active {
				color: $primary-color;
			}
		}
	}

	.profile-menu-mobile {
		@include media-breakpoint-down(md) {
			position: static;
			width: 100%;

			z-index: 1;

			.toggle {
				position: relative;
				&:before,
				&:after {
					position: absolute;
					content: '';
					top: 50%;
					right: -1rem;
					width: rem(34, 14);
					height: rem(34, 14);

					border: 1px solid $primary-color;

					transform: translateY(-50%);
				}

				&:after {
					content: '↓';
					font-family: $font-family-base !important;
					font-weight: 700 !important;

					color: $text-color;
					font-size: rem(18, 14);
					text-align: center;
					line-height: rem(30, 14);
				}
			}

			&.active {
				.toggle {
					margin-bottom: .5rem;

					&:after {
						content: '↑';
					}
				}
			}

			&.stickit {
				top: rem(55);
				right: auto;
				left: rem(35);
				width: calc(100% - #{rem(35 + 35)});
			}

			&.stickit-end {
				top: rem(55);
				bottom: auto;
				right: auto;
				left: rem(35);
				position: fixed;
				width: calc(100% - #{rem(35 + 35)});
			}
		}

		@include media-breakpoint-down(xs) {
			&.stickit {
				left: rem(35, 14);
				width: calc(100% - #{rem(35 + 35, 14)});
			}

			&.stickit-end {
				left: rem(35, 14);
				width: calc(100% - #{rem(35 + 35, 14)});
			}
		}
	}

	.stickem-container {
		position: relative;
		min-height: 50vh;
	}

	.stickit {
		position: fixed;
		top: rem(75);
		right: calc(50% - #{rem((1080) / 2)});
	}

	.stickit-end {
		top: auto;
		bottom: 0;
		position: absolute;
		right: 0;
	}

	.profile-header {
		.square--s6 {
			margin-top: rem(130);

			@include media-breakpoint-down(md) {
				margin-top: 0;
			}

			@include media-breakpoint-down(xs) {
				width: rem(108, 14);
				height: rem(108, 14);

				p {
					font-size: rem(10, 14);
				}
			}
		}

		.square--s8 {
			@include media-breakpoint-down(xs) {
				padding: rem(8, 14) !important;
			}
		}
	}

}