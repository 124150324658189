.strip {
	background: $orange;

	p {

		@include media-breakpoint-down(xs) {
			font-size: rem(12);
		}
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.strip__close {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: rem(40);
	height: rem(40);

	border: 1px solid $text-color-inverse;
	color: $text-color-inverse !important;

	transition: $transition;

	flex-shrink: 0;

	&:hover {
		background: $primary-color;
		border-color: $primary-color;
	}
}