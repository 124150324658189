.ellipsis {
	display: inline-block;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-2 {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.ellipsis-3 {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.ellipsis-4 {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 4;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.ellipsis-5 {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 5;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.ellipsis-6 {
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 6;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}