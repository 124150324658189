.icon {
  position: relative;
  display: block;
  height: auto;
}

.icon--instagram {
  width: rem(30);

  @include media-breakpoint-down(md) {
    width: rem(30 * 1.5);
  }
}

.icon--facebook {
  width: rem(13);

  @include media-breakpoint-down(md) {
    width: rem(13 * 1.5);
  }
}

.close {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxOSAxOSI+PGRlZnM+PHBhdGggZD0iTTIxLjU3IDQwLjJMMzguNzcgMjMiIGlkPSJhIi8+PHBhdGggZD0iTTM4LjIyIDQwLjJMMjEuNTcgMjMiIGlkPSJiIi8+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMSAtMjIpIj48dXNlIHhsaW5rOmhyZWY9IiNhIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzAxYTZlNiIgc3Ryb2tlLW1pdGVybGltaXQ9IjUwIiBzdHJva2Utd2lkdGg9IjIiLz48Zz48dXNlIHhsaW5rOmhyZWY9IiNiIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzAxYTZlNiIgc3Ryb2tlLW1pdGVybGltaXQ9IjUwIiBzdHJva2Utd2lkdGg9IjIiLz48L2c+PC9nPjwvc3ZnPg==');
  background-size: 100% 100% !important;
}