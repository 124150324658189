.testimonial {
	position: relative;
	padding: rem(30) rem(90) rem(30);
	background: $grey-light;

	&:before {
		content: '»';
		position: absolute;
		top: 0;
		left: rem(34);
		font-size: rem(64);
		color: $primary-color;
		line-height: 1;
	}

	@include media-breakpoint-down(xs) {
		padding-right: 1rem;
	}
}

.testimonial__text {

	* {
		&:last-child {
			margin-bottom: .5rem;
		}
 	}

}

.testimonial__date {
	color: $text-color-light;
}