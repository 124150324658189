
html, body {
  min-height: 100vh;
}

// rem responsive
html {

  // font-size: $root-font-size * 1px;
  // font-size: vw($root-font-size * 1px);
  font-size: $root-font-size * 1px;

  // @media (min-width: 1921px) {
  //   font-size: vw($root-font-size * 1px);
  // }

  @media (max-width: 1330px) {
    font-size: vw($root-font-size * 1px);
  }

  @include media-breakpoint-down(lg) {
    // font-size: 14px;
  }

  @include media-breakpoint-down(md) {
    font-size: vw($root-font-size * 1px, 768px);
  }

  @include media-breakpoint-down(sm) {
    font-size: vw(14px, 667px);
  }

  @include media-breakpoint-down(xs) {
    font-size: vw(14px, 375px);
  }
}

body {
  background-color: $text-color-inverse;
  overflow-x: hidden;
  padding-top: rem(78);

  &.has-strip {
    padding-top: rem(125);
  }

  &.show-search {
    height: 100vh;
    overflow: hidden;
  }

  @include media-breakpoint-down(lg) {
    // &.menu-active,
    // &.modal-active {
    //   height: 100vh;
    //   overflow: hidden;
    // }

    // &.menu-active {
    //   height: 100vh;
    //   overflow: hidden;
    // }
  }

  @include media-breakpoint-down(md) {
    padding-top: rem(55);

    &.has-strip {
      padding-top: rem(100);
    }
  }

  @include media-breakpoint-down(xs) {
  }
}

// .body-inner {
//   overflow: hidden;
// }

.container {
  // padding: 3em 2em;
  @include media-breakpoint-down(md) {
    padding-left: rem(35);
    padding-right: rem(35);
  }

  // @include media-breakpoint-down(sm) {
  //   padding-left: rem(25, 12);
  //   padding-right: rem(25, 12);
  // }

  @include media-breakpoint-down(xs) {
    padding-left: rem(35, 14);
    padding-right: rem(35, 14);
  }

  // @include media-breakpoint-down(xxs) {
  //   padding-left: rem(10, 10);
  //   padding-right: rem(10, 10);
  // }
}

// transition little move issue
// https://stackoverflow.com/questions/12980153/image-moves-on-hover-chrome-opacity-issue
*, *:before, *:after {
  // backface-visibility: hidden;
  // transform:rotateZ(0);
  // text-rendering: optimizeLegibility;
  // -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:not(input) {
  &:focus { outline: none; }
}

.main-content {
  overflow: hidden;

  .page--detail & {
    overflow: visible;
  }
}

// lasspass
[id^=__lpform] {
  opacity: 0 !important;
  pointer-events: none !important;
}


// Intercom z-index
// #intercom-container {
//   z-index: 900 !important;
// }

@include media-breakpoint-down(md) {
  #fc_frame:not(.fc-open) {
    display: none !important;
  }
}

// only iOS
@media (max-device-width:1024px) {
  @supports (-webkit-overflow-scrolling: touch) {
    * {
      cursor: pointer;
    }
  }
}