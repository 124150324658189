.nav {

}

.nav__toggle {
  display: none;
  cursor: pointer;
  text-transform: uppercase;
  text-transform: lowercase;
  font-weight: 700;
  color: $text-color-inverse;

  position: absolute;
  z-index: 10;

  width: rem(35, 12);
  height: rem(41, 12);

	right: rem(35);

  

  // &:before {
  //   content: $ic-dot;
  //   font-family: 'psyres';

  //   font-size: rem(40, 12);
  //   color: $secondary-color;

  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }

  &.is-active {

    // &:before {
    //   color: $text-color-inverse;
    // }

    .nav__hamburger {
       // mixin vendor/less-burder.less
      @include burger-to-cross(rem(6), rem(6), $primary-color);

      // &:before,
      // &:after {
      //   background-color: $secondary-color;
      // }
    }

    + .header__nav {
      @include media-breakpoint-down(md) {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.nav__hamburger {
  // mixin vendor/less-burder.less
  @include burger(rem(30), rem(6), rem(6), $primary-color);

  right: 0;
  top: 46%;
  // transform: translateX(%);

  // &:before,
  // &:after {
  //   background-color: $text-color;
  // }
}

.nav__item {
	margin: 0 rem(10);

	&:hover, .active {
		color: $primary-color;
	}

	@include media-breakpoint-down(md) {
		font-size: rem(32) !important;
		margin: 1em 0;
	}

	@include media-breakpoint-down(sm) {
		font-size: rem(20, 14) !important;
		margin: 1rem 0;
	}

	@include media-breakpoint-down(xs) {
		font-size: rem(20, 14) !important;
		margin: 0;
	}
}
