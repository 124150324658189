.search {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: transparentize(#70675e, .1);

	z-index: 11;

	opacity: 0;
	pointer-events: none;

	transition: $transition;

	.show-search & {
		opacity: 1;
		pointer-events: all;
	}
}

.search__container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: rem(70);

	background: $body-bg;
	box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);

	.form-control {
		@extend .text--large3;
		background: $body-bg !important;
	}

	@include media-breakpoint-down(md) {
		padding-left: rem(35);

		.form-control {
			padding: 0;
		}
	}
}