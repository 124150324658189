.keyword {
	height: rem(32);
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;

	background: $primary-color-lightest;

	padding: 0 rem(5);

	transition: $transition;

	&:hover {
		background: $primary-color;
		color: $text-color-inverse;
	}

	@include media-breakpoint-down(md) {
		font-size: rem(12, 14);
		height: rem(20, 14);
		padding: 0 rem(3, 14)
	}

}