.box {
	.note {
		padding: 0;
		padding-left: rem(40);
		background: transparent;

		&:before {
			top: rem(5);
			left: 0;
		}
	}
}

.box--green-light {
	background: $primary-color-form;
}

.box--orange {
	background: $orange;
}

.box--green-diagonale {
	background: url(../images/green-bg-diagonale.png);
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
}