.logo {

}

.logo--benefits {
	position: relative;
	max-width: rem(100);
	max-height: rem(100);
	width: auto !important;
	flex-shrink: 0;

	&.logo--benefity {
		top: rem(-5);
	}

	&.logo--benefit-plus {
		max-width: rem(85);
	}

	&.logo--up,
	&.logo--up-gallery-beta {
		max-width: rem(175);
	}

	&.logo--visa {
		max-width: rem(50);
	}

	&.logo--stripe {
		max-width: rem(100);
	}

	&.logo--paypal {
		max-width: rem(80);
	}


	@include media-breakpoint-down(xs) {
		max-width: rem(65);
		max-height: rem(65);

		&.logo--up,
		&.logo--up-gallery-beta {
			max-width: rem(100);
		}

		&.logo--stripe {
			max-width: rem(75);
		}
	}
}