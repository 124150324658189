.note {

	position: relative;

	background: #f1f0ef;
	padding: rem(13) rem(60) rem(13) rem(140);

	// icon
	&:before {
		content: '!';

		@extend .text--large;

		position: absolute;
		top: rem(18);
		left: rem(95);

		width: rem(25);
		height: rem(25);

		display: flex;
		align-items: center;
		justify-content: center;

		font-weight: 500;

		border: 2px solid $text-color;

	}

	@include media-breakpoint-down(md) {
		padding: 1rem;
		padding-left: 4rem;

		&:before {
			left: 1rem;
		}
	}
}

.note--success {
	background: $primary-color;
	color: $text-color-inverse;
	
	&:before {
		content: '✓';
		color: $text-color-inverse;
		border-color: $text-color-inverse;
		top: rem(14);
	}
}

.note--error {
	background: $error-color;
	color: $text-color-inverse;

	&:before {
		color: $text-color-inverse;
		border-color: $text-color-inverse;
		top: rem(14);

		@include media-breakpoint-down(md) {
			top: rem(18);
		}

		@include media-breakpoint-down(xs) {
			top: rem(13, 14);
		}
	}
}