a:not(.btn) {
  color: $text-color;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

a {
  &:hover,
  &:focus {
    .btn--link {
      background-color: $secondary-color;
    }

    .link {
      color: $secondary-color;
    }
  }
}

.link {
  color: $primary-color;
  text-decoration: none;

  &:hover, &:focus, &:active {
    color: $secondary-color;
    text-decoration: underline;
  }
}

.link--underline {
  text-decoration: underline;
  text-underline-position: 1.5px;

  &:hover {
    text-decoration: none;
  }
}

.link--primary {
  color: $primary-color !important;
  text-decoration: none !important;
  &:hover, &:focus, &:active {
    color: $secondary-color !important;
  }

  &.link--underline {
    text-decoration: underline !important;
    &:hover, &:focus, &:active {
      text-decoration: none !important;
      color: $primary-color !important;
    }
  }
}