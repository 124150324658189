.form-row {
  .page--profile &.disabled {
    .form-control {
      background: $body-bg !important;
      pointer-events: none;
    }
  }
  
  .page--profile & {
    .form-control {
      @extend .text--semi-small;
      color: $text-color !important;
      padding-left: 0;
      font-weight: 700;

      &.textarea {
        
      }
    }

    textarea.form-control {
      height: 7em;
    }
  }
}

.form-group {
  margin-bottom: rem(15);
}

.form-control-label {
  @extend .text--xsmall;
  text-transform: uppercase;
  margin-bottom: .25rem;
}

.form-control {
  // @extend .text--large;
  font-size: 1rem !important;
  color: $text-color !important;
  outline: 0;
  border: none;
  box-shadow: none !important;

  // border-radius: $borderRadiusSmall;
  padding: 0 1rem;
  background: $grey-light !important;

  &:not(textarea) {
    height: rem(40);
  }

  &:required {
    background: $primary-color-form !important;
  }

  &.text-danger {
    box-shadow: inset 0 0 0 2px $error-color !important;
  }

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $primary-color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $primary-color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $primary-color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $primary-color;
  }

  @include media-breakpoint-down(xs) {
    padding-left: 10px;
    padding-right: 10px;

    // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    //   font-size: 14px;
    // }
    // &::-moz-placeholder { /* Firefox 19+ */
    //   font-size: 14px;
    // }
    // &:-ms-input-placeholder { /* IE 10+ */
    //   font-size: 14px;
    // }
    // &:-moz-placeholder { /* Firefox 18- */
    //   font-size: 14px;
    // }
  }
}

.form-control--white {
  background: $text-color-inverse !important;

  &:required {
    background: $text-color-inverse !important;
  }
}

.form-control--search {
  border: 1px solid $primary-color !important;

  @include media-breakpoint-down(xs) {
    padding-left: rem(20);
  }
}

// error
.form-control.parsley-error {
  border: 1px solid $error-color !important;

  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $error-color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $error-color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $error-color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $error-color;
  }

  &[name=birth_month],
  &[name=birth_year] {
    + .parsley-errors-list {
      display: none;
    }
  }
  
  // &:not(select) {
  //   background-image: url(../images/v2/svg/ico-alert.svg) !important;
  //   background-size: rem(20) rem(18) !important;
  //   background-position: center right rem(14) !important;
  //   background-repeat: no-repeat;
  // }

  + .parsley-errors-list {
    list-style: none;
    margin: 0;
    margin-top: .25em;
    padding: 0;
    color: $error-color;
    font-weight: 500;

    li {
      white-space: nowrap;
    }
  }
}

// https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete
// https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 rem(60) $grey-light inset !important;
  -webkit-text-fill-color: $primary-color;

  transition: all 10000s $transition-curve;
}

// @import './inputs/_dropdown';
// @import './inputs/_select';
@import './inputs/_radio';
@import './inputs/_checkbox';
// @import './inputs/_date';

// only iOS
@media (max-device-width:1024px) {
  @supports (-webkit-overflow-scrolling: touch) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
}