.document {
	display: flex;
	align-items: center;
	
	width: rem(235);
}

.document__icon {

	position: relative;

	width: rem(45);
	height: rem(61);

	flex-shrink: 0;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.document__extension {
	position: absolute;

	line-height: 1;

	bottom: rem(5);
	right: rem(5);
}