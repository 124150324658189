.bio-card {

}

.bio-card__figure {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.bio-card__label {
	position: absolute;
	bottom: rem(10);
	right: rem(55);
}