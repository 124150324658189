$cc-color1: #E6E6E6;
$cc-color2: #707070;
$cc-font-size: rem(14);
$cc-transition: all 300ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

$cc-font-family: $font-family-base;


#cm {

	padding: rem(8) 0 !important;

	font-family: $cc-font-family !important;
	// max-width: 300px !important;
	// padding: 20px 26px !important;

	background: $cc-color1 !important;

	// box-shadow: 0px 3px 6px #00000029 !important;

	// @media (max-width: 688px) {
	// 	max-width: 100% !important;
	// }
}

#s-cnt {
	font-family: $cc-font-family !important;
}

// borders
#cm, #s-bl .act .b-acc, #s-inr, .cc_div .b-tg, .cc_div .b-tg .c-tg, .cc_div .b-tg .c-tg:after, .cc_div .b-tl, .cc_div .c-bl, .cc_div .c-bn, .cc_div .c-tgl, .cc_div span.t-lb {
	// border-radius: 0 !important;
}

#cm, #s-bl .act .b-acc, #s-inr, .cc_div .b-tl, .cc_div .c-bl {
	border-radius: 0 !important;
}

// texts
#s-cnt .p,
.b-tl,
#s-ttl,
#c-ttl,
#c-txt {
	color: $cc-color2 !important;
	font-size: $cc-font-size !important;
}

#s-ttl,
#c-ttl {
	font-weight: 700 !important;
	margin-bottom: 0 !important;
}

.b-tl {
	font-weight: 700 !important;
}

// links
.cc_div .cc-link {
}

.c-link {
	color: $cc-color2 !important;
	font-size: $cc-font-size !important;
	font-weight: 700 !important;

	text-align: center !important;

	&:hover {
		text-decoration: underline;
	}
}

#c-p-bn {
	@include media-breakpoint-up(md) {
		margin-left: 1em;
	}
}

#c-bns {
	flex-direction: row-reverse;
	max-width: none !important;

	.c-link {
		margin-left: 0 !important;
	}

	@include media-breakpoint-up(lg) {
		margin-top: 0 !important;
	}
}

#s-bns .c-bn,
#c-bns .c-bn {
	
  // height: rem(30) !important;
  // line-height: rem(24) !important;
	
	padding: 5px 2em 5px !important;
	border-radius: 16px !important;

	font-weight: 700 !important;
	font-size: $cc-font-size !important;
	border: 3px solid $cc-color2 !important;

	white-space: nowrap;

	color: $cc-color2 !important;
	background: transparent !important;

	transition: $cc-transition !important;
	
	&:hover {
		background: #fff !important;
		border-color: #fff !important;
	}
}

#s-bns #s-all-bn {
	border-color: #fff !important;
}

#s-bns #s-rall-bn {
	border-color: #fff !important;
}

#s-bns {
	border: none !important;
	height: auto !important;
	background: $cc-color1 !important;
}

// toggle
.cc_div .b-tg .c-tg {
	background: $cc-color2 !important;
}

.cc_div .b-tg .c-tgl:checked~.c-tg {
	background: #5C9A41 !important;
}

// collapsors
#s-bl .c-bl.b-ex,
#s-cnt .b-bn .b-tl:active, #s-cnt .b-bn .b-tl:hover {
	background: transparent !important;
}

.c-bl.b-ex {
	margin-bottom: 0 !important;
	.b-tl {

		border-top: 1px solid $cc-color1;

		&:before {
			width: rem(25) !important;
			height: rem(25) !important;

			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48ZyBkYXRhLW5hbWU9Ikdyb3VwIDE5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDE1IC01NDEpIj48Y2lyY2xlIGRhdGEtbmFtZT0iRWxsaXBzZSA4IiBjeD0iMTIuNSIgY3k9IjEyLjUiIHI9IjEyLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQxNSA1NDEpIiBmaWxsPSIjNzA3MDcwIi8+PGcgZGF0YS1uYW1lPSJHcm91cCAxMSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZTZlNmU2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMyI+PHBhdGggZGF0YS1uYW1lPSJQYXRoIDIiIGQ9Ik00MjAgNTUzLjVoMTUiLz48cGF0aCBkYXRhLW5hbWU9IlBhdGggMyIgZD0iTTQyNy41IDU2MXYtMTUiLz48L2c+PC9nPjwvc3ZnPg==") !important;
			background-size: 100% 100% !important;

			transition: $cc-transition !important;

			left: 0 !important;
			transform: translateY(-50%) !important;

			border: none !important;
		}
	}

	&.act {
		.b-tl {
			&:before {
				background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48ZyBkYXRhLW5hbWU9Ikdyb3VwIDIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDE1IC00MTYpIj48Y2lyY2xlIGRhdGEtbmFtZT0iRWxsaXBzZSA5IiBjeD0iMTIuNSIgY3k9IjEyLjUiIHI9IjEyLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQxNSA0MTYpIiBmaWxsPSIjZTZlNmU2Ii8+PGcgZGF0YS1uYW1lPSJHcm91cCAxMiI+PHBhdGggZGF0YS1uYW1lPSJQYXRoIDIiIGQ9Ik00MjAgNDI4LjVoMTUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzcwNzA3MCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz48L2c+PC9nPjwvc3ZnPg==") !important;	
			}
		}
	}
}

// header
#s-hdr {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: none !important;
	padding-top: rem(15) !important;
	padding-bottom: rem(15) !important;
	height: auto !important;

	background: $cc-color1 !important;
	* {
		font-weight: 700;
		font-size: rem(18) !important;
	}
}

// close
#s-c-bn {
	background: transparent !important;
	width: rem(25) !important;
	height: rem(25) !important;

	&:after,
	&:before {

		width: rem(25) !important;
		height: rem(25) !important;

		top: 0 !important;
		left: 0 !important;

		transform: none !important;
		border-radius: 0 !important;
	}

	&:before {
		background-color: transparent !important;
		background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48ZyBkYXRhLW5hbWU9Ikdyb3VwIDIxIj48ZyBkYXRhLW5hbWU9IkNvbXBvbmVudCA2IOKAkyAxIj48Y2lyY2xlIGRhdGEtbmFtZT0iRWxsaXBzZSAxMCIgY3g9IjEyLjUiIGN5PSIxMi41IiByPSIxMi41IiBmaWxsPSIjZmZmIi8+PC9nPjxnIGRhdGEtbmFtZT0iR3JvdXAgMTMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2U2ZTZlNiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAyIiBkPSJNNy4xOTcgMTcuODAzIDE3LjgwNCA3LjE5NyIvPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAzIiBkPSJNMTcuODA0IDE3LjgwMyA3LjE5NyA3LjE5NyIvPjwvZz48L2c+PC9zdmc+") !important;
		background-size: 100% 100% !important;

		transition: $cc-transition !important;
	}

	&:after {
		display: none;
	}

	&:hover {
		&:before {
			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48ZyBkYXRhLW5hbWU9Ikdyb3VwIDIxIj48ZyBkYXRhLW5hbWU9IkNvbXBvbmVudCA2IOKAkyAxIj48Y2lyY2xlIGRhdGEtbmFtZT0iRWxsaXBzZSAxMCIgY3g9IjEyLjUiIGN5PSIxMi41IiByPSIxMi41IiBmaWxsPSIjNzA3MDcwIi8+PC9nPjxnIGRhdGEtbmFtZT0iR3JvdXAgMTMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2U2ZTZlNiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAyIiBkPSJNNy4xOTcgMTcuODAzIDE3LjgwNCA3LjE5NyIvPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAzIiBkPSJNMTcuODA0IDE3LjgwMyA3LjE5NyA3LjE5NyIvPjwvZz48L2c+PC9zdmc+") !important;
		}
	}
}


#s-bl tbody tr:hover {
	background: $cc-color1 !important;
}

// table
#s-bl table * {
	color: $cc-color2;
	font-size: rem(14);
}

#s-bl table td:before {
	color: $cc-color2;
}

/* Handle */
#s-bl::-webkit-scrollbar-thumb {
	background: $cc-color1 !important;
}

/* Handle on hover */
#s-bl::-webkit-scrollbar-thumb:hover {
	background: $cc-color2 !important;
}

#c-inr {

	max-width: calc(100% - 2rem) !important;

	@include media-breakpoint-up(lg) {
		display: flex;
		align-items: center;
	
		max-width: rem(1100) !important;
	}
}

#c-inr-i {
	@include media-breakpoint-up(lg) {
		padding-right: 3rem;
	}
}