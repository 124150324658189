h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-bottom: 1em;
}

.text--underline {
  border-bottom: 1px solid $grey;
}

h1, .h1 {
}

h2, .h2 {
}

h3, .h3 {
}

h4, .h4 {
}

h5, .h5 {
}

h6, .h6 {
}

p {
  margin-bottom: 1rem;
}

.text {
  @extend .text--normal;
  font-size: rem($root-font-size);
  font-weight: $font-weight-normal;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--italic {
  font-style: italic;
}

.text--bold, b {
  font-weight: 700;
}

.text--strong, strong {
  font-weight: 700;
}

.text--strike {
  text-decoration: line-through;
}

.text--xsmall {
  font-size: rem(8);
}

small,
.text--small {
  font-size: rem(12);
}

.text--semi-small {
  font-size: rem(14) !important;
}

.text--normal,
.text--normal-size {
  font-size: rem($root-font-size);
}

.text--semi-large {
  font-size: rem(18);
}

.text--large {
  font-size: rem(20);
}

.text--large2 {
  font-size: rem(24);
}

.text--large3 {
  font-size: rem(28) !important;
}

.text--extra-large {
  // font-size: $h2-font-size;
  font-size: rem(32);
}

.text--ultra-large {
  font-size: rem(60);
  letter-spacing: em(-3, 60);

}

.text--primary {
  color: $primary-color !important;
}

.text--secondary {
  color: $secondary-color !important;
}

.text--light {
  color: $text-color-light;
}

.text--error {
  color: $error-color;
}

.text--700 {
  font-weight: 700;
}

.text--500 {
  font-weight: 500;
}

.text--400 {
  font-weight: 400;
}

.text--inverse,
.text--white {
  color: #fff !important;
}

.text--black {
  color: #000;
}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-down(xs) {

  h1, .h1 {
    font-size: rem(24, 14);
  }

  h2, .h2 {
    font-size: rem(20, 14);
  }

  h3, .h3 {
    font-size: rem(16, 14);
  }

  .text--large {
    font-size: rem(12, 14);
  }

  .text--extra-large {
    font-size: rem(16, 14);
  }

  .text--ultra-large {
    font-size: rem(30, 14);
  }

  .text--large3 {
    font-size: rem(20, 14) !important;
  }
}