.checkbox {
  // margin-left: 2px;
}

.form-check {
  padding-left: 0;
}

.form-check-label {

  @extend .text--semi-small;
  position: relative;
  padding-left: rem(35);

  cursor: pointer;
  user-select: none;

  // transition: $transition;

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    transition: $transition;
  }

  // box
  &:before {
    width: rem(16);
    height: rem(16);
    background: $grey-light;
    left: 0;
    top: rem(2);

    border: 1px solid $body-color;
  }

  // check
  &:after {
    content: '✓';
    color: $text-color-inverse;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);

    transition: $transition;

    line-height: 1;
    top: rem(9);
    left: rem(8);
  }

  .accommodation-checkboxes & {
    &:before {
      top: rem(7);
    }

    &:after {
      top: rem(14);
    }
  }

  &:hover {
    // color: $primary-color;
    &:before {
      border-color: $primary-color !important;
    }
  }
}

.form-check-input {
  // display: none;
  position: absolute;
  left: -99999px;
  opacity: 0;

  &:checked ~ .form-check-label:before {
    background: $primary-color;
    border-color: transparent;
  }

  &:checked ~ .form-check-label:after {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  &.text-danger ~ .form-check-label:before {
    box-shadow: inset 0 0 0 2px $error-color !important;
  }
}

.checkbox.parsley-error {

  .form-check-label {
    &:before {
      border-color: $error-color;
    }
  }

  + .parsley-errors-list {
    // @extend .text--semi-small;
    list-style: none;
    margin: 0;
    margin-top: .25em;
    margin-bottom: 1rem;
    padding: 0;
    color: $error-color;

    padding-left: 0;
    font-weight: 500;
  }
}

.checkbox--offseted.parsley-error {
  + .parsley-errors-list {
    padding-left: rem(35);
  }
}

.checkbox--onBg {
  .form-check-label {
    &:before {
      border-color: $primary-color;
      background-color: $text-color-inverse;
    }
  }

  .form-check-input {
    &:checked ~ .form-check-label:before {
      // background: $secondary-color;
    }
  }
}