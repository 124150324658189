@charset "UTF-8";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #BBABFF;
  --pink: #FF96D7;
  --red: #dc3545;
  --orange: #FBB03B;
  --yellow: #FFDD7F;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #FFDD7F;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xxs: 0;
  --breakpoint-xs: 321px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-nb: 1366px;
  --breakpoint-xxl: 1441px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "ibm-plex-sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #70675e;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 2.25rem;
}

h3, .h3 {
  font-size: 1.25rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.container,
.container-fluid,
.container-xxl,
.container-nb,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container-xs, .container {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-xs, .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-xs, .container {
    max-width: 90%;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container {
    max-width: 69.375rem;
  }
}
@media (min-width: 1366px) {
  .container-nb, .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container {
    max-width: 69.375rem;
  }
}
@media (min-width: 1441px) {
  .container-xxl, .container-nb, .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container {
    max-width: 69.375rem;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-nb,
.col-nb-auto, .col-nb-12, .col-nb-11, .col-nb-10, .col-nb-9, .col-nb-8, .col-nb-7, .col-nb-6, .col-nb-5, .col-nb-4, .col-nb-3, .col-nb-2, .col-nb-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-xs,
.col-xs-auto, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 321px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xs-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xs-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xs-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xs-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xs-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xs-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xs-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xs-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xs-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xs-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xs-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    order: -1;
  }
  .order-xs-last {
    order: 13;
  }
  .order-xs-0 {
    order: 0;
  }
  .order-xs-1 {
    order: 1;
  }
  .order-xs-2 {
    order: 2;
  }
  .order-xs-3 {
    order: 3;
  }
  .order-xs-4 {
    order: 4;
  }
  .order-xs-5 {
    order: 5;
  }
  .order-xs-6 {
    order: 6;
  }
  .order-xs-7 {
    order: 7;
  }
  .order-xs-8 {
    order: 8;
  }
  .order-xs-9 {
    order: 9;
  }
  .order-xs-10 {
    order: 10;
  }
  .order-xs-11 {
    order: 11;
  }
  .order-xs-12 {
    order: 12;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.33333333%;
  }
  .offset-xs-2 {
    margin-left: 16.66666667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.33333333%;
  }
  .offset-xs-5 {
    margin-left: 41.66666667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.33333333%;
  }
  .offset-xs-8 {
    margin-left: 66.66666667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.33333333%;
  }
  .offset-xs-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1366px) {
  .col-nb {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-nb-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-nb-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-nb-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-nb-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-nb-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-nb-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-nb-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-nb-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-nb-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-nb-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-nb-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-nb-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-nb-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-nb-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-nb-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-nb-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-nb-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-nb-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-nb-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-nb-first {
    order: -1;
  }
  .order-nb-last {
    order: 13;
  }
  .order-nb-0 {
    order: 0;
  }
  .order-nb-1 {
    order: 1;
  }
  .order-nb-2 {
    order: 2;
  }
  .order-nb-3 {
    order: 3;
  }
  .order-nb-4 {
    order: 4;
  }
  .order-nb-5 {
    order: 5;
  }
  .order-nb-6 {
    order: 6;
  }
  .order-nb-7 {
    order: 7;
  }
  .order-nb-8 {
    order: 8;
  }
  .order-nb-9 {
    order: 9;
  }
  .order-nb-10 {
    order: 10;
  }
  .order-nb-11 {
    order: 11;
  }
  .order-nb-12 {
    order: 12;
  }
  .offset-nb-0 {
    margin-left: 0;
  }
  .offset-nb-1 {
    margin-left: 8.33333333%;
  }
  .offset-nb-2 {
    margin-left: 16.66666667%;
  }
  .offset-nb-3 {
    margin-left: 25%;
  }
  .offset-nb-4 {
    margin-left: 33.33333333%;
  }
  .offset-nb-5 {
    margin-left: 41.66666667%;
  }
  .offset-nb-6 {
    margin-left: 50%;
  }
  .offset-nb-7 {
    margin-left: 58.33333333%;
  }
  .offset-nb-8 {
    margin-left: 66.66666667%;
  }
  .offset-nb-9 {
    margin-left: 75%;
  }
  .offset-nb-10 {
    margin-left: 83.33333333%;
  }
  .offset-nb-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1441px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #70675e;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e5e5e5;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e5e5e5;
}
.table tbody + tbody {
  border-top: 2px solid #e5e5e5;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e5e5e5;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e5e5e5;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #70675e;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff5db;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffedbc;
}

.table-hover .table-warning:hover {
  background-color: #ffeec2;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffeec2;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e5e5e5;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 320.98px) {
  .table-responsive-xs {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xs > .table-bordered {
    border: 0;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1365.98px) {
  .table-responsive-nb {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-nb > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1440.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.375em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.375;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.375;
  color: #70675e;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.375;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.375em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.34375em + 0.1875rem) center;
  background-size: calc(0.6875em + 0.375rem) calc(0.6875em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.375em + 0.75rem);
  background-position: top calc(0.34375em + 0.1875rem) right calc(0.34375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.6875em + 0.375rem) calc(0.6875em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.375;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.375em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.34375em + 0.1875rem) center;
  background-size: calc(0.6875em + 0.375rem) calc(0.6875em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.375em + 0.75rem);
  background-position: top calc(0.34375em + 0.1875rem) right calc(0.34375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.6875em + 0.375rem) calc(0.6875em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .pagination__item a {
  display: inline-block;
  font-weight: 400;
  color: #70675e;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .pagination__item a {
    transition: none;
  }
}
.btn:hover, .pagination__item a:hover {
  color: #70675e;
  text-decoration: none;
}
.btn:focus, .pagination__item a:focus, .btn.focus, .pagination__item a.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .pagination__item a.disabled, .btn:disabled, .pagination__item a:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled), .pagination__item a:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled, .pagination__item a.disabled,
fieldset:disabled a.btn,
fieldset:disabled .pagination__item a,
.pagination__item fieldset:disabled a {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #FFDD7F;
  border-color: #FFDD7F;
}
.btn-warning:hover {
  color: #212529;
  background-color: #ffd359;
  border-color: #ffcf4c;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #ffd359;
  border-color: #ffcf4c;
  box-shadow: 0 0 0 0.2rem rgba(222, 193, 114, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #FFDD7F;
  border-color: #FFDD7F;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffcf4c;
  border-color: #ffcc3f;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 193, 114, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #FFDD7F;
  border-color: #FFDD7F;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #FFDD7F;
  border-color: #FFDD7F;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 221, 127, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #FFDD7F;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #FFDD7F;
  border-color: #FFDD7F;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 221, 127, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #857342;
  background-color: #fff8e5;
  border-color: #fff5db;
}
.alert-warning hr {
  border-top-color: #ffeec2;
}
.alert-warning .alert-link {
  color: #635631;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #FFDD7F !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffcf4c !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #e5e5e5 !important;
}

.border-top {
  border-top: 1px solid #e5e5e5 !important;
}

.border-right {
  border-right: 1px solid #e5e5e5 !important;
}

.border-bottom {
  border-bottom: 1px solid #e5e5e5 !important;
}

.border-left {
  border-left: 1px solid #e5e5e5 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #FFDD7F !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 321px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1366px) {
  .d-nb-none {
    display: none !important;
  }
  .d-nb-inline {
    display: inline !important;
  }
  .d-nb-inline-block {
    display: inline-block !important;
  }
  .d-nb-block {
    display: block !important;
  }
  .d-nb-table {
    display: table !important;
  }
  .d-nb-table-row {
    display: table-row !important;
  }
  .d-nb-table-cell {
    display: table-cell !important;
  }
  .d-nb-flex {
    display: flex !important;
  }
  .d-nb-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1441px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 321px) {
  .flex-xs-row {
    flex-direction: row !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xs-fill {
    flex: 1 1 auto !important;
  }
  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .align-items-xs-start {
    align-items: flex-start !important;
  }
  .align-items-xs-end {
    align-items: flex-end !important;
  }
  .align-items-xs-center {
    align-items: center !important;
  }
  .align-items-xs-baseline {
    align-items: baseline !important;
  }
  .align-items-xs-stretch {
    align-items: stretch !important;
  }
  .align-content-xs-start {
    align-content: flex-start !important;
  }
  .align-content-xs-end {
    align-content: flex-end !important;
  }
  .align-content-xs-center {
    align-content: center !important;
  }
  .align-content-xs-between {
    align-content: space-between !important;
  }
  .align-content-xs-around {
    align-content: space-around !important;
  }
  .align-content-xs-stretch {
    align-content: stretch !important;
  }
  .align-self-xs-auto {
    align-self: auto !important;
  }
  .align-self-xs-start {
    align-self: flex-start !important;
  }
  .align-self-xs-end {
    align-self: flex-end !important;
  }
  .align-self-xs-center {
    align-self: center !important;
  }
  .align-self-xs-baseline {
    align-self: baseline !important;
  }
  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1366px) {
  .flex-nb-row {
    flex-direction: row !important;
  }
  .flex-nb-column {
    flex-direction: column !important;
  }
  .flex-nb-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-nb-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-nb-wrap {
    flex-wrap: wrap !important;
  }
  .flex-nb-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-nb-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-nb-fill {
    flex: 1 1 auto !important;
  }
  .flex-nb-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-nb-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-nb-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-nb-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-nb-start {
    justify-content: flex-start !important;
  }
  .justify-content-nb-end {
    justify-content: flex-end !important;
  }
  .justify-content-nb-center {
    justify-content: center !important;
  }
  .justify-content-nb-between {
    justify-content: space-between !important;
  }
  .justify-content-nb-around {
    justify-content: space-around !important;
  }
  .align-items-nb-start {
    align-items: flex-start !important;
  }
  .align-items-nb-end {
    align-items: flex-end !important;
  }
  .align-items-nb-center {
    align-items: center !important;
  }
  .align-items-nb-baseline {
    align-items: baseline !important;
  }
  .align-items-nb-stretch {
    align-items: stretch !important;
  }
  .align-content-nb-start {
    align-content: flex-start !important;
  }
  .align-content-nb-end {
    align-content: flex-end !important;
  }
  .align-content-nb-center {
    align-content: center !important;
  }
  .align-content-nb-between {
    align-content: space-between !important;
  }
  .align-content-nb-around {
    align-content: space-around !important;
  }
  .align-content-nb-stretch {
    align-content: stretch !important;
  }
  .align-self-nb-auto {
    align-self: auto !important;
  }
  .align-self-nb-start {
    align-self: flex-start !important;
  }
  .align-self-nb-end {
    align-self: flex-end !important;
  }
  .align-self-nb-center {
    align-self: center !important;
  }
  .align-self-nb-baseline {
    align-self: baseline !important;
  }
  .align-self-nb-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1441px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 321px) {
  .float-xs-left {
    float: left !important;
  }
  .float-xs-right {
    float: right !important;
  }
  .float-xs-none {
    float: none !important;
  }
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1366px) {
  .float-nb-left {
    float: left !important;
  }
  .float-nb-right {
    float: right !important;
  }
  .float-nb-none {
    float: none !important;
  }
}
@media (min-width: 1441px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 321px) {
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }
  .m-xs-1 {
    margin: 0.25rem !important;
  }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important;
  }
  .m-xs-3 {
    margin: 1rem !important;
  }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important;
  }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important;
  }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important;
  }
  .m-xs-4 {
    margin: 1.5rem !important;
  }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important;
  }
  .m-xs-5 {
    margin: 3rem !important;
  }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important;
  }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important;
  }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }
  .p-xs-1 {
    padding: 0.25rem !important;
  }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important;
  }
  .p-xs-3 {
    padding: 1rem !important;
  }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important;
  }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important;
  }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important;
  }
  .p-xs-4 {
    padding: 1.5rem !important;
  }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important;
  }
  .p-xs-5 {
    padding: 3rem !important;
  }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important;
  }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important;
  }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important;
  }
  .m-xs-n1 {
    margin: -0.25rem !important;
  }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xs-n2 {
    margin: -0.5rem !important;
  }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xs-n3 {
    margin: -1rem !important;
  }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important;
  }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important;
  }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important;
  }
  .m-xs-n4 {
    margin: -1.5rem !important;
  }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xs-n5 {
    margin: -3rem !important;
  }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important;
  }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important;
  }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1366px) {
  .m-nb-0 {
    margin: 0 !important;
  }
  .mt-nb-0,
  .my-nb-0 {
    margin-top: 0 !important;
  }
  .mr-nb-0,
  .mx-nb-0 {
    margin-right: 0 !important;
  }
  .mb-nb-0,
  .my-nb-0 {
    margin-bottom: 0 !important;
  }
  .ml-nb-0,
  .mx-nb-0 {
    margin-left: 0 !important;
  }
  .m-nb-1 {
    margin: 0.25rem !important;
  }
  .mt-nb-1,
  .my-nb-1 {
    margin-top: 0.25rem !important;
  }
  .mr-nb-1,
  .mx-nb-1 {
    margin-right: 0.25rem !important;
  }
  .mb-nb-1,
  .my-nb-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-nb-1,
  .mx-nb-1 {
    margin-left: 0.25rem !important;
  }
  .m-nb-2 {
    margin: 0.5rem !important;
  }
  .mt-nb-2,
  .my-nb-2 {
    margin-top: 0.5rem !important;
  }
  .mr-nb-2,
  .mx-nb-2 {
    margin-right: 0.5rem !important;
  }
  .mb-nb-2,
  .my-nb-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-nb-2,
  .mx-nb-2 {
    margin-left: 0.5rem !important;
  }
  .m-nb-3 {
    margin: 1rem !important;
  }
  .mt-nb-3,
  .my-nb-3 {
    margin-top: 1rem !important;
  }
  .mr-nb-3,
  .mx-nb-3 {
    margin-right: 1rem !important;
  }
  .mb-nb-3,
  .my-nb-3 {
    margin-bottom: 1rem !important;
  }
  .ml-nb-3,
  .mx-nb-3 {
    margin-left: 1rem !important;
  }
  .m-nb-4 {
    margin: 1.5rem !important;
  }
  .mt-nb-4,
  .my-nb-4 {
    margin-top: 1.5rem !important;
  }
  .mr-nb-4,
  .mx-nb-4 {
    margin-right: 1.5rem !important;
  }
  .mb-nb-4,
  .my-nb-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-nb-4,
  .mx-nb-4 {
    margin-left: 1.5rem !important;
  }
  .m-nb-5 {
    margin: 3rem !important;
  }
  .mt-nb-5,
  .my-nb-5 {
    margin-top: 3rem !important;
  }
  .mr-nb-5,
  .mx-nb-5 {
    margin-right: 3rem !important;
  }
  .mb-nb-5,
  .my-nb-5 {
    margin-bottom: 3rem !important;
  }
  .ml-nb-5,
  .mx-nb-5 {
    margin-left: 3rem !important;
  }
  .p-nb-0 {
    padding: 0 !important;
  }
  .pt-nb-0,
  .py-nb-0 {
    padding-top: 0 !important;
  }
  .pr-nb-0,
  .px-nb-0 {
    padding-right: 0 !important;
  }
  .pb-nb-0,
  .py-nb-0 {
    padding-bottom: 0 !important;
  }
  .pl-nb-0,
  .px-nb-0 {
    padding-left: 0 !important;
  }
  .p-nb-1 {
    padding: 0.25rem !important;
  }
  .pt-nb-1,
  .py-nb-1 {
    padding-top: 0.25rem !important;
  }
  .pr-nb-1,
  .px-nb-1 {
    padding-right: 0.25rem !important;
  }
  .pb-nb-1,
  .py-nb-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-nb-1,
  .px-nb-1 {
    padding-left: 0.25rem !important;
  }
  .p-nb-2 {
    padding: 0.5rem !important;
  }
  .pt-nb-2,
  .py-nb-2 {
    padding-top: 0.5rem !important;
  }
  .pr-nb-2,
  .px-nb-2 {
    padding-right: 0.5rem !important;
  }
  .pb-nb-2,
  .py-nb-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-nb-2,
  .px-nb-2 {
    padding-left: 0.5rem !important;
  }
  .p-nb-3 {
    padding: 1rem !important;
  }
  .pt-nb-3,
  .py-nb-3 {
    padding-top: 1rem !important;
  }
  .pr-nb-3,
  .px-nb-3 {
    padding-right: 1rem !important;
  }
  .pb-nb-3,
  .py-nb-3 {
    padding-bottom: 1rem !important;
  }
  .pl-nb-3,
  .px-nb-3 {
    padding-left: 1rem !important;
  }
  .p-nb-4 {
    padding: 1.5rem !important;
  }
  .pt-nb-4,
  .py-nb-4 {
    padding-top: 1.5rem !important;
  }
  .pr-nb-4,
  .px-nb-4 {
    padding-right: 1.5rem !important;
  }
  .pb-nb-4,
  .py-nb-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-nb-4,
  .px-nb-4 {
    padding-left: 1.5rem !important;
  }
  .p-nb-5 {
    padding: 3rem !important;
  }
  .pt-nb-5,
  .py-nb-5 {
    padding-top: 3rem !important;
  }
  .pr-nb-5,
  .px-nb-5 {
    padding-right: 3rem !important;
  }
  .pb-nb-5,
  .py-nb-5 {
    padding-bottom: 3rem !important;
  }
  .pl-nb-5,
  .px-nb-5 {
    padding-left: 3rem !important;
  }
  .m-nb-n1 {
    margin: -0.25rem !important;
  }
  .mt-nb-n1,
  .my-nb-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-nb-n1,
  .mx-nb-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-nb-n1,
  .my-nb-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-nb-n1,
  .mx-nb-n1 {
    margin-left: -0.25rem !important;
  }
  .m-nb-n2 {
    margin: -0.5rem !important;
  }
  .mt-nb-n2,
  .my-nb-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-nb-n2,
  .mx-nb-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-nb-n2,
  .my-nb-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-nb-n2,
  .mx-nb-n2 {
    margin-left: -0.5rem !important;
  }
  .m-nb-n3 {
    margin: -1rem !important;
  }
  .mt-nb-n3,
  .my-nb-n3 {
    margin-top: -1rem !important;
  }
  .mr-nb-n3,
  .mx-nb-n3 {
    margin-right: -1rem !important;
  }
  .mb-nb-n3,
  .my-nb-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-nb-n3,
  .mx-nb-n3 {
    margin-left: -1rem !important;
  }
  .m-nb-n4 {
    margin: -1.5rem !important;
  }
  .mt-nb-n4,
  .my-nb-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-nb-n4,
  .mx-nb-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-nb-n4,
  .my-nb-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-nb-n4,
  .mx-nb-n4 {
    margin-left: -1.5rem !important;
  }
  .m-nb-n5 {
    margin: -3rem !important;
  }
  .mt-nb-n5,
  .my-nb-n5 {
    margin-top: -3rem !important;
  }
  .mr-nb-n5,
  .mx-nb-n5 {
    margin-right: -3rem !important;
  }
  .mb-nb-n5,
  .my-nb-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-nb-n5,
  .mx-nb-n5 {
    margin-left: -3rem !important;
  }
  .m-nb-auto {
    margin: auto !important;
  }
  .mt-nb-auto,
  .my-nb-auto {
    margin-top: auto !important;
  }
  .mr-nb-auto,
  .mx-nb-auto {
    margin-right: auto !important;
  }
  .mb-nb-auto,
  .my-nb-auto {
    margin-bottom: auto !important;
  }
  .ml-nb-auto,
  .mx-nb-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1441px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 321px) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1366px) {
  .text-nb-left {
    text-align: left !important;
  }
  .text-nb-right {
    text-align: right !important;
  }
  .text-nb-center {
    text-align: center !important;
  }
}
@media (min-width: 1441px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #FFDD7F !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ffc933 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #70675e !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e5e5e5;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e5e5e5;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 4.125rem;
  width: 4.125rem;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 1;
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1);
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: #73E673;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "ibm-plex-sans", sans-serif;
  line-height: 1;
  color: #00cea5;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 1.875rem;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: 1.875rem;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 1.875rem;
}
[dir=rtl] .slick-next {
  left: 1.875rem;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 1.875rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "ibm-plex-sans", sans-serif;
  font-size: 0.625rem;
  line-height: 20px;
  text-align: center;
  color: #00cea5;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: #00cea5;
  opacity: 1;
}

.flex-equal {
  flex: 1;
}

.p-abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.screen {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.no-events {
  pointer-events: none !important;
}

.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.bg-primary {
  background-color: #00cea5 !important;
}

.bg-secondary {
  background-color: #73E673;
}

.bg-grey {
  background-color: #e2e1df;
}

.bg-grey-light {
  background-color: #F1F0EF;
}

.bg-gGreen {
  background-image: linear-gradient(to right, #73e673 0%, #00cea5 100%);
}

.bg-green-lightest {
  background-color: #99ebdb;
}

.modalHidden {
  transform-origin: center !important;
  transform: translateY(-100%) !important;
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1) !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

.modalShowed {
  transform: translateY(0) !important;
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1) !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

.greyToWhiteFilter {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='brightness'><feColorMatrix type='matrix' values='1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0 0 0 0 0 1.2 0'/></filter></svg>#brightness"); /* Firefox 3.5+ */
  filter: brightness(108.5%); /* Chrome 19+ & Safari 6+ */
}

.lh-08 {
  line-height: 0.8;
}

.lh-09 {
  line-height: 0.9;
}

.lh-1 {
  line-height: 1;
}

.lh-115 {
  line-height: 1.15;
}

.lh-125 {
  line-height: 1.25;
}

.z1 {
  position: relative;
  z-index: 1;
}

.ls-thin {
  letter-spacing: -0.4375rem;
}

.border-bottom {
  border-bottom: 1px solid #e2e1df;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-bottom: 1em;
}

.text--underline {
  border-bottom: 1px solid #e2e1df;
}

p {
  margin-bottom: 1rem;
}

.text {
  font-size: 1rem;
  font-weight: 400;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--italic {
  font-style: italic;
}

.text--bold, b {
  font-weight: 700;
}

.text--strong, strong {
  font-weight: 700;
}

.text--strike {
  text-decoration: line-through;
}

.text--xsmall, .form-control-label {
  font-size: 0.5rem;
}

small,
.text--small,
.btn,
.pagination__item a {
  font-size: 0.75rem;
}

.text--semi-small, .form-check-label, .form-radio-label, .page--profile .form-row .form-control, .btn--link.square--s1:after, .pagination__item--prev a.square--s1:after,
.pagination__item--next a.square--s1:after {
  font-size: 0.875rem !important;
}

.text--normal, .text,
.text--normal-size {
  font-size: 1rem;
}

.text--semi-large, .pagination__item a, .btn--link.square--s3:after, .pagination__item--prev a.square--s3:after,
.pagination__item--next a.square--s3:after, .pagination__item a.btn--link:after, .pagination__item .pagination__item--prev a:after, .pagination__item--prev .pagination__item a:after,
.pagination__item .pagination__item--next a:after,
.pagination__item--next .pagination__item a:after {
  font-size: 1.125rem;
}

.text--large, .note:before, .tag .square, .tag .pagination__item a, .pagination__item .tag a, .slick-prev:before,
.slick-next:before {
  font-size: 1.25rem;
}

.text--large2 {
  font-size: 1.5rem;
}

.text--large3, .search__container .form-control {
  font-size: 1.75rem !important;
}

.text--extra-large {
  font-size: 2rem;
}

.text--ultra-large {
  font-size: 3.75rem;
  letter-spacing: -0.05em;
}

.text--primary {
  color: #00cea5 !important;
}

.text--secondary {
  color: #73E673 !important;
}

.text--light {
  color: #c6c2bf;
}

.text--error {
  color: #FBB03B;
}

.text--700 {
  font-weight: 700;
}

.text--500 {
  font-weight: 500;
}

.text--400 {
  font-weight: 400;
}

.text--inverse,
.text--white {
  color: #fff !important;
}

.text--black {
  color: #000;
}

@media (max-width: 575.98px) {
  h1, .h1 {
    font-size: 1.7142857143rem;
  }
  h2, .h2 {
    font-size: 1.4285714286rem;
  }
  h3, .h3 {
    font-size: 1.1428571429rem;
  }
  .text--large, .note:before, .tag .square, .tag .pagination__item a, .pagination__item .tag a, .slick-prev:before,
  .slick-next:before {
    font-size: 0.8571428571rem;
  }
  .text--extra-large {
    font-size: 1.1428571429rem;
  }
  .text--ultra-large {
    font-size: 2.1428571429rem;
  }
  .text--large3, .search__container .form-control {
    font-size: 1.4285714286rem !important;
  }
}
.icon {
  position: relative;
  display: block;
  height: auto;
}

.icon--instagram {
  width: 1.875rem;
}
@media (max-width: 991.98px) {
  .icon--instagram {
    width: 2.8125rem;
  }
}

.icon--facebook {
  width: 0.8125rem;
}
@media (max-width: 991.98px) {
  .icon--facebook {
    width: 1.21875rem;
  }
}

.close {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxOSAxOSI+PGRlZnM+PHBhdGggZD0iTTIxLjU3IDQwLjJMMzguNzcgMjMiIGlkPSJhIi8+PHBhdGggZD0iTTM4LjIyIDQwLjJMMjEuNTcgMjMiIGlkPSJiIi8+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMSAtMjIpIj48dXNlIHhsaW5rOmhyZWY9IiNhIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzAxYTZlNiIgc3Ryb2tlLW1pdGVybGltaXQ9IjUwIiBzdHJva2Utd2lkdGg9IjIiLz48Zz48dXNlIHhsaW5rOmhyZWY9IiNiIiBmaWxsLW9wYWNpdHk9IjAiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzAxYTZlNiIgc3Ryb2tlLW1pdGVybGltaXQ9IjUwIiBzdHJva2Utd2lkdGg9IjIiLz48L2c+PC9nPjwvc3ZnPg==");
  background-size: 100% 100% !important;
}

.btn, .pagination__item a {
  border: none;
  position: relative;
  text-transform: uppercase;
  min-width: 8.125rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 3.5rem 0 1rem;
  font-weight: 500;
  background-color: #33D8B7;
  box-shadow: none !important;
  white-space: nowrap;
}
.btn:before, .pagination__item a:before, .btn:after, .pagination__item a:after {
  font-family: "ibm-plex-sans", sans-serif !important;
  font-weight: 700 !important;
}
.btn:before, .pagination__item a:before {
  content: "→";
  position: absolute;
  right: 0;
  font-size: 1.125rem;
  font-weight: 700;
  width: 2.5rem;
  height: 100%;
  background: #00cea5;
  line-height: 2.3125rem;
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1);
}
.btn.square, .pagination__item a {
  min-width: auto;
  background-color: #00cea5;
}
.btn.square:before, .pagination__item a:before {
  display: none;
}

a.btn:hover, .pagination__item a:hover, a.btn:focus, .pagination__item a:focus,
input.btn:hover,
input.btn:focus,
button.btn:hover,
button.btn:focus {
  background-color: #8FEB8F;
}
a.btn:hover:before, .pagination__item a:hover:before, a.btn:focus:before, .pagination__item a:focus:before,
input.btn:hover:before,
input.btn:focus:before,
button.btn:hover:before,
button.btn:focus:before {
  background-color: #73E673;
}

.btn--back {
  padding: 0 1rem 0 3.5rem;
  min-width: auto;
}
.btn--back:before {
  content: "←";
  right: auto;
  left: 0;
}

.btn--transparent, .pagination__item:not(.pagination__item--prev):not(.pagination__item--next) a {
  background: transparent;
}
.btn--transparent.btn--bordered:hover, .pagination__item:not(.pagination__item--prev):not(.pagination__item--next) a.btn--bordered:hover, .btn--transparent.btn--bordered:focus, .pagination__item:not(.pagination__item--prev):not(.pagination__item--next) a.btn--bordered:focus {
  background: transparent;
}
.btn--transparent.btn--bordered:hover:before, .pagination__item:not(.pagination__item--prev):not(.pagination__item--next) a.btn--bordered:hover:before, .btn--transparent.btn--bordered:focus:before, .pagination__item:not(.pagination__item--prev):not(.pagination__item--next) a.btn--bordered:focus:before {
  border-color: #73E673;
}

.btn--bordered:before {
  border: 1px solid #00cea5;
  background: transparent;
}

.btn--border, .pagination__item:not(.pagination__item--prev):not(.pagination__item--next) a {
  border: 1px solid #00cea5;
}
.btn--border:hover, .pagination__item:not(.pagination__item--prev):not(.pagination__item--next) a:hover, .btn--border:focus, .pagination__item:not(.pagination__item--prev):not(.pagination__item--next) a:focus {
  border-color: #73E673;
}

.btn--link, .pagination__item--prev a,
.pagination__item--next a {
  position: relative;
}
.btn--link:after, .pagination__item--prev a:after,
.pagination__item--next a:after {
  content: "↘";
  font-weight: 700;
  font-size: 1.75rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn--link-left:after, .pagination__item--prev a:after {
  content: "←";
}

.btn--link-right:after, .pagination__item--next a:after {
  content: "→";
}

.btn--link-top:after {
  content: "↗";
}

.btn--search {
  background-image: url(../images/icons/ico-search.svg);
  background-size: 1.3185rem 1.22475rem;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -99999px;
}

.btn--profile {
  background-image: url(../images/icons/ico-profile.svg);
  background-size: 1.47rem 1.311875rem;
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -99999px;
}

a:not(.btn) {
  color: #70675e;
}
a:not(.btn):hover, a:not(.btn):focus, a:not(.btn):active {
  text-decoration: none;
}

a:hover .btn--link, a:hover .pagination__item--prev a, .pagination__item--prev a:hover a,
a:hover .pagination__item--next a,
.pagination__item--next a:hover a, a:focus .btn--link, a:focus .pagination__item--prev a, .pagination__item--prev a:focus a,
a:focus .pagination__item--next a,
.pagination__item--next a:focus a {
  background-color: #73E673;
}
a:hover .link, a:hover .news__content a, .news__content a:hover a, a:focus .link, a:focus .news__content a, .news__content a:focus a {
  color: #73E673;
}

.link, .news__content a {
  color: #00cea5;
  text-decoration: none;
}
.link:hover, .news__content a:hover, .link:focus, .news__content a:focus, .link:active, .news__content a:active {
  color: #73E673;
  text-decoration: underline;
}

.link--underline {
  text-decoration: underline;
  text-underline-position: 1.5px;
}
.link--underline:hover {
  text-decoration: none;
}

.link--primary {
  color: #00cea5 !important;
  text-decoration: none !important;
}
.link--primary:hover, .link--primary:focus, .link--primary:active {
  color: #73E673 !important;
}
.link--primary.link--underline {
  text-decoration: underline !important;
}
.link--primary.link--underline:hover, .link--primary.link--underline:focus, .link--primary.link--underline:active {
  text-decoration: none !important;
  color: #00cea5 !important;
}

.list--no-style {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list {
  margin-bottom: 1.5rem;
}

.list__item {
  margin-bottom: 0.8125rem;
}

.list--inline {
  display: flex;
}

.page--profile .form-row.disabled .form-control {
  background: #fff !important;
  pointer-events: none;
}
.page--profile .form-row .form-control {
  color: #70675e !important;
  padding-left: 0;
  font-weight: 700;
}
.page--profile .form-row textarea.form-control {
  height: 7em;
}

.form-group {
  margin-bottom: 0.9375rem;
}

.form-control-label {
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}

.form-control {
  font-size: 1rem !important;
  color: #70675e !important;
  outline: 0;
  border: none;
  box-shadow: none !important;
  padding: 0 1rem;
  background: #F1F0EF !important;
}
.form-control:not(textarea) {
  height: 2.5rem;
}
.form-control:required {
  background: #E6FAF6 !important;
}
.form-control.text-danger {
  box-shadow: inset 0 0 0 2px #FBB03B !important;
}
.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #00cea5;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
  color: #00cea5;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
  color: #00cea5;
}
.form-control:-moz-placeholder { /* Firefox 18- */
  color: #00cea5;
}
@media (max-width: 575.98px) {
  .form-control {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.form-control--white {
  background: #fff !important;
}
.form-control--white:required {
  background: #fff !important;
}

.form-control--search {
  border: 1px solid #00cea5 !important;
}
@media (max-width: 575.98px) {
  .form-control--search {
    padding-left: 1.25rem;
  }
}

.form-control.parsley-error {
  border: 1px solid #FBB03B !important;
}
.form-control.parsley-error::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #FBB03B;
}
.form-control.parsley-error::-moz-placeholder { /* Firefox 19+ */
  color: #FBB03B;
}
.form-control.parsley-error:-ms-input-placeholder { /* IE 10+ */
  color: #FBB03B;
}
.form-control.parsley-error:-moz-placeholder { /* Firefox 18- */
  color: #FBB03B;
}
.form-control.parsley-error[name=birth_month] + .parsley-errors-list, .form-control.parsley-error[name=birth_year] + .parsley-errors-list {
  display: none;
}
.form-control.parsley-error + .parsley-errors-list {
  list-style: none;
  margin: 0;
  margin-top: 0.25em;
  padding: 0;
  color: #FBB03B;
  font-weight: 500;
}
.form-control.parsley-error + .parsley-errors-list li {
  white-space: nowrap;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 3.75rem #F1F0EF inset !important;
  -webkit-text-fill-color: #00cea5;
  transition: all 10000s cubic-bezier(0.77, 0, 0.175, 1);
}

.form-radio {
  padding-left: 0;
}

.form-radio-label {
  position: relative;
  padding-left: 2.1875rem;
  cursor: pointer;
  user-select: none;
}
.form-radio-label:before, .form-radio-label:after {
  content: "";
  position: absolute;
  display: block;
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1);
  border-radius: 50%;
  overflow: hidden;
}
.form-radio-label:before {
  width: 1rem;
  height: 1rem;
  background: #F1F0EF;
  left: 0;
  top: 0.125rem;
  border: 1px solid #70675e;
}
.form-radio-label:after {
  display: none;
}
.form-radio-label:hover:before {
  border-color: #00cea5 !important;
}

.form-radio-input {
  position: absolute;
  left: -99999px;
  opacity: 0;
}
.form-radio-input:checked ~ .form-radio-label:before {
  background: #00cea5;
  border-color: transparent;
}
.form-radio-input:checked ~ .form-radio-label:after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.form-radio-input.text-danger ~ .form-radio-label:before {
  box-shadow: inset 0 0 0 2px #FBB03B !important;
}

.radio.parsley-error .form-radio-label:before {
  border-color: #FBB03B;
}
.radio.parsley-error + .parsley-errors-list {
  list-style: none;
  margin: 0;
  margin-top: 0.25em;
  margin-bottom: 1rem;
  padding: 0;
  color: #FBB03B;
  font-weight: 500;
  padding-left: 0;
}

.radio--onBg .form-radio-label:before {
  border-color: #00cea5;
  background-color: #fff;
}
.form-check {
  padding-left: 0;
}

.form-check-label {
  position: relative;
  padding-left: 2.1875rem;
  cursor: pointer;
  user-select: none;
}
.form-check-label:before, .form-check-label:after {
  content: "";
  position: absolute;
  display: block;
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1);
}
.form-check-label:before {
  width: 1rem;
  height: 1rem;
  background: #F1F0EF;
  left: 0;
  top: 0.125rem;
  border: 1px solid #70675e;
}
.form-check-label:after {
  content: "✓";
  color: #fff;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1);
  line-height: 1;
  top: 0.5625rem;
  left: 0.5rem;
}
.accommodation-checkboxes .form-check-label:before {
  top: 0.4375rem;
}
.accommodation-checkboxes .form-check-label:after {
  top: 0.875rem;
}
.form-check-label:hover:before {
  border-color: #00cea5 !important;
}

.form-check-input {
  position: absolute;
  left: -99999px;
  opacity: 0;
}
.form-check-input:checked ~ .form-check-label:before {
  background: #00cea5;
  border-color: transparent;
}
.form-check-input:checked ~ .form-check-label:after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.form-check-input.text-danger ~ .form-check-label:before {
  box-shadow: inset 0 0 0 2px #FBB03B !important;
}

.checkbox.parsley-error .form-check-label:before {
  border-color: #FBB03B;
}
.checkbox.parsley-error + .parsley-errors-list {
  list-style: none;
  margin: 0;
  margin-top: 0.25em;
  margin-bottom: 1rem;
  padding: 0;
  color: #FBB03B;
  padding-left: 0;
  font-weight: 500;
}

.checkbox--offseted.parsley-error + .parsley-errors-list {
  padding-left: 2.1875rem;
}

.checkbox--onBg .form-check-label:before {
  border-color: #00cea5;
  background-color: #fff;
}
@media (max-device-width: 1024px) {
  @supports (-webkit-overflow-scrolling: touch) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
}
.table-responsive {
  overflow: auto;
}

.table {
  width: 100%;
}
.table th {
  color: #00cea5;
  padding-bottom: 1.5rem;
  font-weight: normal;
}
.table td {
  padding-top: 0.46875rem;
  padding-bottom: 0.46875rem;
}

.table--bookings .ellipsis {
  position: relative;
  top: 0.3125rem;
  max-width: 17.5rem;
}
@media (max-width: 991.98px) {
  .table--bookings, .table--bookings tbody, .table--bookings tr, .table--bookings td {
    display: block;
    width: 100%;
    text-align: left !important;
    padding: 0 !important;
  }
  .table--bookings thead {
    display: none;
  }
  .table--bookings th, .table--bookings td {
    margin-bottom: 0.5rem;
  }
  .table--bookings tr {
    margin-bottom: 5rem;
  }
  .table--bookings td:before {
    content: attr(data-label);
    margin-right: 0.25rem;
    color: #00cea5;
    display: block;
  }
  .table--bookings .btn, .table--bookings .pagination__item a, .pagination__item .table--bookings a {
    margin-top: 1rem;
  }
  .table--bookings .ellipsis {
    display: block;
    max-width: none;
    white-space: normal;
    overflow: auto;
    text-overflow: initial;
    position: static;
  }
}

.table--wallet {
  width: auto;
}
.table--wallet tr td:nth-of-type(2),
.table--wallet tr th:nth-of-type(2) {
  padding-left: 8rem;
  padding-right: 5rem;
}
.table--wallet tr td:nth-of-type(4),
.table--wallet tr th:nth-of-type(4) {
  padding-left: 5rem;
}
@media (max-width: 1199.98px) {
  .table--wallet {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .table--wallet thead {
    display: none;
  }
  .table--wallet, .table--wallet tbody, .table--wallet tr, .table--wallet td, .table--wallet th {
    display: block;
    width: 100%;
    text-align: left !important;
    padding: 0 !important;
  }
  .table--wallet th, .table--wallet td {
    margin-bottom: 0.5rem;
  }
  .table--wallet tr {
    margin-bottom: 2rem;
  }
}

.table--planner td, .table--planner th {
  padding: 0 1rem 1rem;
  vertical-align: top;
}
.table--planner .td--planner-group,
.table--planner .td--planner-quantity,
.table--planner .td--planner-price {
  vertical-align: middle;
}
.table--planner .td--planner-group {
  width: 10rem;
}
.table--planner .td--planner-price-buy,
.table--planner .td--planner-group-buy,
.table--planner .td--planner-group-buy,
.table--planner .td--planner-group {
  line-height: 1;
}
.table--planner .td--planner-price-buy,
.table--planner .td--planner-group-buy {
  vertical-align: top;
}
@media (max-width: 991.98px) {
  .table--planner .personPicker {
    width: 30rem;
  }
  .table--planner thead {
    display: none;
  }
  .table--planner, .table--planner tbody, .table--planner tr, .table--planner td, .table--planner th {
    display: block;
    width: 100% !important;
    text-align: left !important;
    padding: 0 !important;
  }
  .table--planner th, .table--planner td {
    margin-bottom: 0.5rem;
  }
  .table--planner tr {
    margin-bottom: 2rem;
  }
}
@media (max-width: 575.98px) {
  .table--planner .personPicker {
    width: 100%;
  }
}

.ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipsis-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipsis-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.ellipsis-6 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.logo--benefits {
  position: relative;
  max-width: 6.25rem;
  max-height: 6.25rem;
  width: auto !important;
  flex-shrink: 0;
}
.logo--benefits.logo--benefity {
  top: -0.3125rem;
}
.logo--benefits.logo--benefit-plus {
  max-width: 5.3125rem;
}
.logo--benefits.logo--up, .logo--benefits.logo--up-gallery-beta {
  max-width: 10.9375rem;
}
.logo--benefits.logo--visa {
  max-width: 3.125rem;
}
.logo--benefits.logo--stripe {
  max-width: 6.25rem;
}
.logo--benefits.logo--paypal {
  max-width: 5rem;
}
@media (max-width: 575.98px) {
  .logo--benefits {
    max-width: 4.0625rem;
    max-height: 4.0625rem;
  }
  .logo--benefits.logo--up, .logo--benefits.logo--up-gallery-beta {
    max-width: 6.25rem;
  }
  .logo--benefits.logo--stripe {
    max-width: 4.6875rem;
  }
}

.popup--watchNow {
  position: fixed;
  bottom: 1.25rem;
  left: 1.25rem;
  background: #00cea5;
  padding: 0.9375rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  z-index: 10;
  transform: translateY(150%);
  opacity: 0;
  pointer-events: none;
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1);
}
.popup--watchNow.active {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
}
@media (max-width: 991.98px) {
  .popup--watchNow {
    display: none;
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.25);
  z-index: 9999999999;
  transition: opacity 150ms cubic-bezier(0.77, 0, 0.175, 1);
}
.waiting-for-load .loading {
  opacity: 1;
  pointer-events: all;
}
.waiting-for-load .loading .loading__icon div {
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loading__icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
}
.loading__icon div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  border-color: #fff transparent transparent transparent;
}
.loading__icon div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading__icon div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading__icon div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#cm {
  padding: 0.5rem 0 !important;
  font-family: "ibm-plex-sans", sans-serif !important;
  background: #E6E6E6 !important;
}

#s-cnt {
  font-family: "ibm-plex-sans", sans-serif !important;
}

#cm, #s-bl .act .b-acc, #s-inr, .cc_div .b-tl, .cc_div .c-bl {
  border-radius: 0 !important;
}

#s-cnt .p,
.b-tl,
#s-ttl,
#c-ttl,
#c-txt {
  color: #707070 !important;
  font-size: 0.875rem !important;
}

#s-ttl,
#c-ttl {
  font-weight: 700 !important;
  margin-bottom: 0 !important;
}

.b-tl {
  font-weight: 700 !important;
}

.c-link {
  color: #707070 !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  text-align: center !important;
}
.c-link:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  #c-p-bn {
    margin-left: 1em;
  }
}

#c-bns {
  flex-direction: row-reverse;
  max-width: none !important;
}
#c-bns .c-link {
  margin-left: 0 !important;
}
@media (min-width: 992px) {
  #c-bns {
    margin-top: 0 !important;
  }
}

#s-bns .c-bn,
#c-bns .c-bn {
  padding: 5px 2em 5px !important;
  border-radius: 16px !important;
  font-weight: 700 !important;
  font-size: 0.875rem !important;
  border: 3px solid #707070 !important;
  white-space: nowrap;
  color: #707070 !important;
  background: transparent !important;
  transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1) !important;
}
#s-bns .c-bn:hover,
#c-bns .c-bn:hover {
  background: #fff !important;
  border-color: #fff !important;
}

#s-bns #s-all-bn {
  border-color: #fff !important;
}

#s-bns #s-rall-bn {
  border-color: #fff !important;
}

#s-bns {
  border: none !important;
  height: auto !important;
  background: #E6E6E6 !important;
}

.cc_div .b-tg .c-tg {
  background: #707070 !important;
}

.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  background: #5C9A41 !important;
}

#s-bl .c-bl.b-ex,
#s-cnt .b-bn .b-tl:active, #s-cnt .b-bn .b-tl:hover {
  background: transparent !important;
}

.c-bl.b-ex {
  margin-bottom: 0 !important;
}
.c-bl.b-ex .b-tl {
  border-top: 1px solid #E6E6E6;
}
.c-bl.b-ex .b-tl:before {
  width: 1.5625rem !important;
  height: 1.5625rem !important;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48ZyBkYXRhLW5hbWU9Ikdyb3VwIDE5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDE1IC01NDEpIj48Y2lyY2xlIGRhdGEtbmFtZT0iRWxsaXBzZSA4IiBjeD0iMTIuNSIgY3k9IjEyLjUiIHI9IjEyLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQxNSA1NDEpIiBmaWxsPSIjNzA3MDcwIi8+PGcgZGF0YS1uYW1lPSJHcm91cCAxMSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZTZlNmU2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMyI+PHBhdGggZGF0YS1uYW1lPSJQYXRoIDIiIGQ9Ik00MjAgNTUzLjVoMTUiLz48cGF0aCBkYXRhLW5hbWU9IlBhdGggMyIgZD0iTTQyNy41IDU2MXYtMTUiLz48L2c+PC9nPjwvc3ZnPg==") !important;
  background-size: 100% 100% !important;
  transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1) !important;
  left: 0 !important;
  transform: translateY(-50%) !important;
  border: none !important;
}
.c-bl.b-ex.act .b-tl:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48ZyBkYXRhLW5hbWU9Ikdyb3VwIDIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDE1IC00MTYpIj48Y2lyY2xlIGRhdGEtbmFtZT0iRWxsaXBzZSA5IiBjeD0iMTIuNSIgY3k9IjEyLjUiIHI9IjEyLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQxNSA0MTYpIiBmaWxsPSIjZTZlNmU2Ii8+PGcgZGF0YS1uYW1lPSJHcm91cCAxMiI+PHBhdGggZGF0YS1uYW1lPSJQYXRoIDIiIGQ9Ik00MjAgNDI4LjVoMTUiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzcwNzA3MCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz48L2c+PC9nPjwvc3ZnPg==") !important;
}

#s-hdr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none !important;
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
  height: auto !important;
  background: #E6E6E6 !important;
}
#s-hdr * {
  font-weight: 700;
  font-size: 1.125rem !important;
}

#s-c-bn {
  background: transparent !important;
  width: 1.5625rem !important;
  height: 1.5625rem !important;
}
#s-c-bn:after, #s-c-bn:before {
  width: 1.5625rem !important;
  height: 1.5625rem !important;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;
  border-radius: 0 !important;
}
#s-c-bn:before {
  background-color: transparent !important;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48ZyBkYXRhLW5hbWU9Ikdyb3VwIDIxIj48ZyBkYXRhLW5hbWU9IkNvbXBvbmVudCA2IOKAkyAxIj48Y2lyY2xlIGRhdGEtbmFtZT0iRWxsaXBzZSAxMCIgY3g9IjEyLjUiIGN5PSIxMi41IiByPSIxMi41IiBmaWxsPSIjZmZmIi8+PC9nPjxnIGRhdGEtbmFtZT0iR3JvdXAgMTMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2U2ZTZlNiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAyIiBkPSJNNy4xOTcgMTcuODAzIDE3LjgwNCA3LjE5NyIvPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAzIiBkPSJNMTcuODA0IDE3LjgwMyA3LjE5NyA3LjE5NyIvPjwvZz48L2c+PC9zdmc+") !important;
  background-size: 100% 100% !important;
  transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1) !important;
}
#s-c-bn:after {
  display: none;
}
#s-c-bn:hover:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI1IDI1Ij48ZyBkYXRhLW5hbWU9Ikdyb3VwIDIxIj48ZyBkYXRhLW5hbWU9IkNvbXBvbmVudCA2IOKAkyAxIj48Y2lyY2xlIGRhdGEtbmFtZT0iRWxsaXBzZSAxMCIgY3g9IjEyLjUiIGN5PSIxMi41IiByPSIxMi41IiBmaWxsPSIjNzA3MDcwIi8+PC9nPjxnIGRhdGEtbmFtZT0iR3JvdXAgMTMiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2U2ZTZlNiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAyIiBkPSJNNy4xOTcgMTcuODAzIDE3LjgwNCA3LjE5NyIvPjxwYXRoIGRhdGEtbmFtZT0iUGF0aCAzIiBkPSJNMTcuODA0IDE3LjgwMyA3LjE5NyA3LjE5NyIvPjwvZz48L2c+PC9zdmc+") !important;
}

#s-bl tbody tr:hover {
  background: #E6E6E6 !important;
}

#s-bl table * {
  color: #707070;
  font-size: 0.875rem;
}

#s-bl table td:before {
  color: #707070;
}

/* Handle */
#s-bl::-webkit-scrollbar-thumb {
  background: #E6E6E6 !important;
}

/* Handle on hover */
#s-bl::-webkit-scrollbar-thumb:hover {
  background: #707070 !important;
}

#c-inr {
  max-width: calc(100% - 2rem) !important;
}
@media (min-width: 992px) {
  #c-inr {
    display: flex;
    align-items: center;
    max-width: 68.75rem !important;
  }
}

@media (min-width: 992px) {
  #c-inr-i {
    padding-right: 3rem;
  }
}

.nav__toggle {
  display: none;
  cursor: pointer;
  text-transform: uppercase;
  text-transform: lowercase;
  font-weight: 700;
  color: #fff;
  position: absolute;
  z-index: 10;
  width: 2.9166666667rem;
  height: 3.4166666667rem;
  right: 2.1875rem;
}
.nav__toggle.is-active .nav__hamburger:before, .nav__toggle.is-active .nav__hamburger:after {
  background-color: #00cea5;
}
.nav__toggle.is-active .nav__hamburger {
  background-color: transparent;
}
.nav__toggle.is-active .nav__hamburger:before {
  -webkit-transform: translateY(0.75rem) rotate(45deg);
  -moz-transform: translateY(0.75rem) rotate(45deg);
  -ms-transform: translateY(0.75rem) rotate(45deg);
  -o-transform: translateY(0.75rem) rotate(45deg);
  transform: translateY(0.75rem) rotate(45deg);
}
.nav__toggle.is-active .nav__hamburger:after {
  -webkit-transform: translateY(-0.75rem) rotate(-45deg);
  -moz-transform: translateY(-0.75rem) rotate(-45deg);
  -ms-transform: translateY(-0.75rem) rotate(-45deg);
  -o-transform: translateY(-0.75rem) rotate(-45deg);
  transform: translateY(-0.75rem) rotate(-45deg);
}
@media (max-width: 991.98px) {
  .nav__toggle.is-active + .header__nav {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (max-width: 991.98px) {
  .nav__toggle {
    display: block;
  }
}

.nav__hamburger {
  position: absolute;
  margin-top: 0;
  margin-bottom: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  right: 0;
  top: 46%;
}
.nav__hamburger, .nav__hamburger:before, .nav__hamburger:after {
  width: 1.875rem;
  height: 0.375rem;
  background-color: #00cea5;
  border-radius: 0;
  -webkit-transition-property: background-color, -webkit-transform;
  -moz-transition-property: background-color, -moz-transform;
  -o-transition-property: background-color, -o-transform;
  transition-property: background-color, transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.nav__hamburger:before, .nav__hamburger:after {
  display: block;
  position: absolute;
  content: "";
}
.nav__hamburger:before {
  top: -0.75rem;
}
.nav__hamburger:after {
  top: 0.75rem;
}

.nav__item {
  margin: 0 0.625rem;
}
.nav__item:hover, .nav__item .active {
  color: #00cea5;
}
@media (max-width: 991.98px) {
  .nav__item {
    font-size: 2rem !important;
    margin: 1em 0;
  }
}
@media (max-width: 767.98px) {
  .nav__item {
    font-size: 1.4285714286rem !important;
    margin: 1rem 0;
  }
}
@media (max-width: 575.98px) {
  .nav__item {
    font-size: 1.4285714286rem !important;
    margin: 0;
  }
}

.square, .pagination__item a {
  position: relative;
  padding: 0.5rem;
  flex-shrink: 0;
}
@media (max-width: 575.98px) {
  .square, .pagination__item a {
    padding: 0.2857142857rem;
  }
}

.square__footer {
  position: absolute;
  bottom: 0.9375rem;
  left: 0.5rem;
  width: 100%;
}

.square--border {
  border-bottom: 0.3125rem solid #00cea5;
}

.square--link {
  position: relative;
}
.square--link .square__link {
  position: absolute;
  bottom: 0;
  right: 0;
}

a.square--link:hover .square__link {
  background: #73E673;
}

.square--white {
  background: #fff;
}

.square--A,
.square--yellow {
  background: #FFDD7F;
}

.square--gYellow,
.square--gradient-yellow {
  background-image: linear-gradient(45deg, #ffec88 0%, #ffdd7f 100%);
}

.square--orange {
  background: #FBB03B;
}

.square--gPink,
.square--gradient-pink {
  background-image: linear-gradient(45deg, #ffccff 0%, #ff96d7 100%);
}

.square--B,
.square--pink {
  background: #FF96D7;
}

.square--gPurple,
.square--gradient-purple {
  background-image: linear-gradient(45deg, #bcdcff 0%, #bbabff 100%);
}

.square--C,
.square--purple {
  background: #BBABFF;
}

.square--gGreen,
.square--gradient-green {
  background-image: linear-gradient(45deg, #73e673 0%, #00cea5 100%);
}

.square--D,
.square--green {
  background: #00cea5;
}

.square--green-light {
  background: #e3fae3;
}

.square--green-diagonale {
  background: url(../images/green-bg-diagonale.svg);
  background-size: cover;
}
.square--green-diagonale strong,
.square--green-diagonale span {
  position: absolute;
}
.square--green-diagonale strong {
  top: 0.9375rem;
  left: 1rem;
}
.square--green-diagonale span {
  bottom: 0.9375rem;
  left: 1rem;
  width: calc(100% - 2rem);
}

.square--disabled {
  background: #70675e;
}

.square--s1 {
  width: 1.5625rem;
  height: 1.5625rem;
}

.square--s2 {
  width: 1.75rem;
  height: 1.75rem;
}

.square--s3, .pagination__item a {
  width: 2.5rem;
  height: 2.5rem;
}

.square--s4 {
  width: 3.125rem;
  height: 3.125rem;
}

.square--s5 {
  width: 5.75rem;
  height: 5.75rem;
}
@media (max-width: 575.98px) {
  .square--s5 {
    width: 4.2857142857rem;
    height: 4.2857142857rem;
  }
}

.square--s6 {
  width: 10.5rem;
  height: 10.5rem;
}
@media (max-width: 575.98px) {
  .square--s6 {
    padding: 0.5714285714rem;
  }
}

.square--s7 {
  width: 11.5625rem;
  height: 11.5625rem;
}
.square--s7 .square__footer {
  bottom: 0.3125rem;
}
@media (max-width: 991.98px) {
  .square--s7 {
    width: 12rem;
    height: 12rem;
  }
}
@media (max-width: 575.98px) {
  .square--s7 {
    width: 10.4285714286rem;
    height: 10.4285714286rem;
    padding: 1rem;
  }
}

.square--s8 {
  width: 16.25rem;
  height: 16.25rem;
}
@media (max-width: 575.98px) {
  .square--s8 {
    width: 10.4285714286rem;
    height: 10.4285714286rem;
  }
}

.square--s9 {
  width: 17.3125rem;
  height: 17.3125rem;
  padding: 0.9375rem;
}
.square--s9 .square__footer {
  bottom: 1.875rem;
  left: 0.9375rem;
}

.square--s10 {
  width: 28.75rem;
  height: 28.75rem;
  padding: 1.25rem;
}
.square--s10 .square__footer {
  left: 1.25rem;
  width: calc(100% - 2.5rem);
  bottom: 1.25rem;
}
.square--s10.square--border {
  border-width: 0.5rem;
}
.square--s10.square--elastic {
  height: auto;
  min-height: 28.75rem;
}
@media (min-width: 992px) {
  .square--s10.square--elastic .square__title {
    margin-bottom: 9.6875rem;
  }
}

img.square {
  object-fit: cover;
}

.bio-card__figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bio-card__label {
  position: absolute;
  bottom: 0.625rem;
  right: 3.4375rem;
}

.tag {
  display: flex;
  align-items: center;
  max-width: 6.875rem;
}
@media (max-width: 575.98px) {
  .tag {
    flex-direction: column;
    align-items: flex-start;
  }
}

.tag--big {
  width: 6.875rem;
}
.tag--big .square, .tag--big .pagination__item a, .pagination__item .tag--big a {
  font-size: 1.75rem;
}

.course {
  max-width: 39.375rem;
}
@media (max-width: 991.98px) {
  .course {
    max-width: none;
  }
}

.course__figure {
  position: relative;
  flex-shrink: 0;
}
.course__figure .square, .course__figure .pagination__item a, .pagination__item .course__figure a {
  position: absolute;
  top: 0;
  left: 0;
}
.course__figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course__location--online {
  background: #13cda3;
  color: #fff;
  padding: 0rem 0.3125rem 0.1875rem;
}

.pagination {
  list-style: none;
  display: flex;
}

.pagination__item {
  margin: 0.625rem;
}
.pagination__item a {
  padding: 0 !important;
}
.pagination__item.active a {
  background-color: #00cea5 !important;
  border: none !important;
}

.pagination__item--prev a:after,
.pagination__item--next a:after {
  font-size: 1.125rem !important;
}

.note {
  position: relative;
  background: #f1f0ef;
  padding: 0.8125rem 3.75rem 0.8125rem 8.75rem;
}
.note:before {
  content: "!";
  position: absolute;
  top: 1.125rem;
  left: 5.9375rem;
  width: 1.5625rem;
  height: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border: 2px solid #70675e;
}
@media (max-width: 991.98px) {
  .note {
    padding: 1rem;
    padding-left: 4rem;
  }
  .note:before {
    left: 1rem;
  }
}

.note--success {
  background: #00cea5;
  color: #fff;
}
.note--success:before {
  content: "✓";
  color: #fff;
  border-color: #fff;
  top: 0.875rem;
}

.note--error {
  background: #FBB03B;
  color: #fff;
}
.note--error:before {
  color: #fff;
  border-color: #fff;
  top: 0.875rem;
}
@media (max-width: 991.98px) {
  .note--error:before {
    top: 1.125rem;
  }
}
@media (max-width: 575.98px) {
  .note--error:before {
    top: 0.9285714286rem;
  }
}

.document {
  display: flex;
  align-items: center;
  width: 14.6875rem;
}

.document__icon {
  position: relative;
  width: 2.8125rem;
  height: 3.8125rem;
  flex-shrink: 0;
}
.document__icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.document__extension {
  position: absolute;
  line-height: 1;
  bottom: 0.3125rem;
  right: 0.3125rem;
}

.keyword {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  background: #99ebdb;
  padding: 0 0.3125rem;
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1);
}
.keyword:hover {
  background: #00cea5;
  color: #fff;
}
@media (max-width: 991.98px) {
  .keyword {
    font-size: 0.8571428571rem;
    height: 1.4285714286rem;
    padding: 0 0.2142857143rem;
  }
}

.alert {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.box .note {
  padding: 0;
  padding-left: 2.5rem;
  background: transparent;
}
.box .note:before {
  top: 0.3125rem;
  left: 0;
}

.box--green-light {
  background: #E6FAF6;
}

.box--orange {
  background: #FBB03B;
}

.box--green-diagonale {
  background: url(../images/green-bg-diagonale.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(112, 103, 94, 0.9);
  z-index: 11;
  opacity: 0;
  pointer-events: none;
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1);
}
.show-search .search {
  opacity: 1;
  pointer-events: all;
}

.search__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.375rem;
  background: #fff;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
}
.search__container .form-control {
  background: #fff !important;
}
@media (max-width: 991.98px) {
  .search__container {
    padding-left: 2.1875rem;
  }
  .search__container .form-control {
    padding: 0;
  }
}

.tips .slick-list,
.tips .slick-track,
.tips .slick-slide,
.tips div {
  height: 100%;
}

.tips__item {
  height: 100%;
  width: 100%;
  position: relative;
}

.testimonial {
  position: relative;
  padding: 1.875rem 5.625rem 1.875rem;
  background: #F1F0EF;
}
.testimonial:before {
  content: "»";
  position: absolute;
  top: 0;
  left: 2.125rem;
  font-size: 4rem;
  color: #00cea5;
  line-height: 1;
}
@media (max-width: 575.98px) {
  .testimonial {
    padding-right: 1rem;
  }
}

.testimonial__text *:last-child {
  margin-bottom: 0.5rem;
}

.testimonial__date {
  color: #c6c2bf;
}

.promo {
  position: relative;
  overflow: hidden;
}
.promo:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  background: linear-gradient(to left, #00cea5 0%, rgba(115, 230, 115, 0.6) 90%, rgba(115, 230, 115, 0) 100%);
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 991.98px) {
  .promo:before {
    width: 100%;
    background: linear-gradient(to right, #00cea5 0%, rgba(115, 230, 115, 0.65) 100%);
  }
}

.promo__title {
  font-size: 3rem;
}
@media (max-width: 575.98px) {
  .promo__title {
    font-size: 1.7142857143rem;
  }
}

.strip {
  background: #FBB03B;
}
@media (max-width: 575.98px) {
  .strip p {
    font-size: 0.75rem;
  }
}
.strip p:last-child {
  margin-bottom: 0;
}

.strip__close {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid #fff;
  color: #fff !important;
  transition: all 150ms cubic-bezier(0.77, 0, 0.175, 1);
  flex-shrink: 0;
}
.strip__close:hover {
  background: #00cea5;
  border-color: #00cea5;
}

.news__content a {
  font-weight: 700;
}
.news__content p:last-child {
  margin-bottom: 0;
}

html, body {
  min-height: 100vh;
}

html {
  font-size: 16px;
}
@media (max-width: 1330px) {
  html {
    font-size: 1.1713030747vw;
  }
}
@media (max-width: 991.98px) {
  html {
    font-size: 2.0833333333vw;
  }
}
@media (max-width: 767.98px) {
  html {
    font-size: 2.0989505247vw;
  }
}
@media (max-width: 575.98px) {
  html {
    font-size: 3.7333333333vw;
  }
}

body {
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 4.875rem;
}
body.has-strip {
  padding-top: 7.8125rem;
}
body.show-search {
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 991.98px) {
  body {
    padding-top: 3.4375rem;
  }
  body.has-strip {
    padding-top: 6.25rem;
  }
}
@media (max-width: 991.98px) {
  .container {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }
}
@media (max-width: 575.98px) {
  .container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

*, *:before, *:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:not(input):focus {
  outline: none;
}

.main-content {
  overflow: hidden;
}
.page--detail .main-content {
  overflow: visible;
}

[id^=__lpform] {
  opacity: 0 !important;
  pointer-events: none !important;
}

@media (max-width: 991.98px) {
  #fc_frame:not(.fc-open) {
    display: none !important;
  }
}
@media (max-device-width: 1024px) {
  @supports (-webkit-overflow-scrolling: touch) {
    * {
      cursor: pointer;
    }
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
  z-index: 5;
}

.header__container {
  height: 4.375rem;
}
@media (max-width: 991.98px) {
  .header__container {
    height: 3.4375rem;
  }
}

.header__logo img {
  width: 6.875rem;
}
@media (max-width: 991.98px) {
  .header__logo img {
    width: 6.125rem;
  }
}

.header__content {
  width: 100%;
  padding-left: 4.0625rem;
}
@media (max-width: 991.98px) {
  .header__content {
    padding: 0 4.6875rem 0 0;
  }
}
@media (max-width: 575.98px) {
  .header__content {
    padding-right: 3.5714285714rem !important;
  }
}

.header__profile:hover .square, .header__profile:hover .pagination__item a, .pagination__item .header__profile:hover a, .header__profile:focus .square, .header__profile:focus .pagination__item a, .pagination__item .header__profile:focus a {
  background-color: #73E673 !important;
}

@media (max-width: 991.98px) {
  .header__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 3.375rem;
    left: 0;
    width: 100%;
    max-height: calc(100vh - 3.375rem);
    overflow: auto;
    padding: 3rem 0 3rem;
    background: #fff;
    transform: translateY(calc(-100% - 3.4375rem));
    transition: 300ms 150ms cubic-bezier(0.77, 0, 0.175, 1);
    opacity: 0;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
  }
}
@media (max-width: 767.98px) {
  .header__nav {
    height: calc(100vh - 3.375rem);
    max-height: none;
  }
}
@media (max-width: 575.98px) {
  .header__nav {
    justify-content: space-between;
    padding: 10rem 0;
  }
}

.teaser {
  position: relative;
  width: 100%;
  height: 29.4375rem;
  margin-bottom: 9.375rem;
  z-index: 2;
}
@media (max-width: 991.98px) {
  .teaser {
    margin-bottom: 21.875rem;
  }
  .teaser .container {
    max-width: none;
    padding: 0;
  }
}
@media (max-width: 575.98px) {
  .teaser {
    height: auto;
    margin-bottom: 0;
  }
}

.teaser__image {
  position: relative;
  width: 39.25rem;
  height: 100%;
  flex-shrink: 0;
}
.teaser__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.teaser__image .square, .teaser__image .pagination__item a, .pagination__item .teaser__image a {
  position: absolute;
}
.teaser__image .square--1 {
  left: 0;
  bottom: 0;
}
.teaser__image .square--2 {
  bottom: 5.75rem;
  left: 5.75rem;
}
.teaser__image .square--3 {
  bottom: 3.25rem;
  left: 8.25rem;
}
.teaser__image .square--4 {
  bottom: 0;
  right: 12.25rem;
}
@media (max-width: 991.98px) {
  .teaser__image {
    width: 100%;
  }
  .teaser__image .square--4 {
    right: auto;
    left: 31.5rem;
  }
}
@media (max-width: 575.98px) {
  .teaser__image {
    height: 20rem;
  }
}

.teaser__content {
  position: relative;
  width: 100%;
}
@media (max-width: 991.98px) {
  .teaser__content {
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100% - 12rem));
  }
  .teaser__content .text-right .btn, .teaser__content .text-right .pagination__item a, .pagination__item .teaser__content .text-right a {
    position: absolute;
    left: 43.5rem;
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .teaser__content {
    position: relative;
    transform: none;
    margin-top: -12.5714285714rem;
    bottom: auto;
    left: 2.5rem;
    width: calc(100% - 5rem);
  }
  .teaser__content .text-right .btn, .teaser__content .text-right .pagination__item a, .pagination__item .teaser__content .text-right a {
    position: relative;
    left: auto;
    transform: none;
  }
}

.teaser__courses {
  position: relative;
  height: 23.125rem;
}
.teaser__courses .square, .teaser__courses .pagination__item a, .pagination__item .teaser__courses a {
  position: absolute;
}
.teaser__courses .square--1 {
  top: 0;
  right: 23.125rem;
}
.teaser__courses .square--2 {
  top: 0;
  right: 11.5625rem;
}
.teaser__courses .square--3 {
  top: 11.5625rem;
  right: 0;
}
@media (max-width: 991.98px) {
  .teaser__courses {
    width: 100%;
  }
  .teaser__courses .square--1 {
    left: 2.1875rem;
  }
  .teaser__courses .square--2 {
    left: 19.5rem;
    right: auto;
  }
  .teaser__courses .square--3 {
    left: 31.5rem;
    right: auto;
    top: 11.9375rem;
  }
}
@media (max-width: 575.98px) {
  .teaser__courses {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
  .teaser__courses > .square, .pagination__item .teaser__courses > a {
    position: relative;
    left: auto;
    right: auto;
    top: auto;
  }
  .teaser__courses .square--1 {
    width: 19.7142857143rem;
    height: 19.7142857143rem;
  }
  .teaser__courses .square--2,
  .teaser__courses .square--3 {
    width: 10.8571428571rem;
    height: 10.8571428571rem;
    padding: 0.2857142857rem;
  }
  .teaser__courses .square--2 .square__footer,
  .teaser__courses .square--3 .square__footer {
    left: 0.2857142857rem;
  }
}

.section .container > .h1:first-child,
.section .container > .h2:first-child {
  margin-top: 0;
}

.section--kdo-jsme .square--2,
.section--kdo-jsme .square--4 {
  position: relative;
  top: 1.875rem;
}
.section--kdo-jsme .square--5 {
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (max-width: 991.98px) {
  .section--kdo-jsme .container > .d-flex {
    margin-left: -2.1875rem;
    margin-right: -2.1875rem;
  }
  .section--kdo-jsme .img-kdo-jsme {
    margin-left: -2.1875rem;
    margin-right: -2.1875rem;
  }
  .section--kdo-jsme .bg-grey-light {
    position: relative;
    background: none;
    padding-top: 2rem;
    padding-bottom: 0;
  }
  .section--kdo-jsme .bg-grey-light:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100%;
    background: #F1F0EF;
    pointer-events: none;
    z-index: -1;
  }
}
@media (max-width: 575.98px) {
  .section--kdo-jsme .container > .d-flex {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .section--kdo-jsme .img-kdo-jsme {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .section--kdo-jsme .square--1,
  .section--kdo-jsme .square--2,
  .section--kdo-jsme .square--3,
  .section--kdo-jsme .square--4 {
    width: 6.7142857143rem;
    height: 6.7142857143rem;
    padding: 0.5714285714rem 0.2857142857rem;
  }
  .section--kdo-jsme .square--1 span,
  .section--kdo-jsme .square--2 span,
  .section--kdo-jsme .square--3 span,
  .section--kdo-jsme .square--4 span {
    display: block;
  }
  .section--kdo-jsme .square--1 span:first-child,
  .section--kdo-jsme .square--2 span:first-child,
  .section--kdo-jsme .square--3 span:first-child,
  .section--kdo-jsme .square--4 span:first-child {
    line-height: 1;
  }
  .section--kdo-jsme .square--1 span:last-child,
  .section--kdo-jsme .square--2 span:last-child,
  .section--kdo-jsme .square--3 span:last-child,
  .section--kdo-jsme .square--4 span:last-child {
    font-size: 0.7142857143rem;
    line-height: 1.4;
  }
}

.section--lectors .h2 {
  background: #E3FAE3;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: -4rem;
}
.section--lectors .bio-card {
  position: relative;
  top: 0.9375rem;
}
.section--lectors .bio-card:nth-of-type(odd) {
  top: -3.125rem;
}
@media (max-width: 991.98px) {
  .section--lectors .container > .d-flex {
    margin-left: -2.1875rem;
    margin-right: -2.1875rem;
  }
}

.section--login .square--login .square__footer {
  bottom: -1px;
  left: 0;
}

.section--nwl {
  position: relative;
  z-index: 2;
}

.section--recommendation .square--2 {
  margin-top: 11.5625rem;
}
.section--recommendation .btn--abs {
  position: absolute;
  bottom: 0;
  right: -1.875rem;
}
@media (max-width: 991.98px) {
  .section--recommendation .btn--abs {
    bottom: -3.4375rem;
    right: 0;
  }
}
@media (max-width: 575.98px) {
  .section--recommendation .square--s7 {
    width: 50%;
    height: 10.8571428571rem;
    padding: 0.2857142857rem;
  }
  .section--recommendation .square--s7 .square__footer {
    left: 0.2857142857rem;
    width: calc(100% - 0.5714285714rem);
  }
}

@media (max-width: 991.98px) {
  .section--order {
    margin-left: -2.1875rem;
    margin-right: -2.1875rem;
  }
  .section--order .order {
    padding: 1rem 2.1875rem !important;
  }
}
@media (max-width: 575.98px) {
  .section--order {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .section--order .order {
    padding: 1rem 2.5rem !important;
  }
}

@media (max-width: 991.98px) {
  .section--note .note {
    margin-left: -2.1875rem;
    margin-right: -2.1875rem;
    padding-right: 2.1875rem;
    padding-left: calc(4rem + 2.1875rem);
  }
  .section--note .note:before {
    left: 2.1875rem;
  }
}
@media (max-width: 575.98px) {
  .section--note .note {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    padding-right: 2.1875rem;
    padding-left: calc(3rem + 2.5rem);
  }
  .section--note .note:before {
    left: 2.5rem;
  }
}

.section--kurzy-na-klic .promo {
  background-image: url(../images/kurzy-na-klic.jpg);
  background-size: cover;
}

.section--news .border-right:last-child {
  border-right: none !important;
}
@media (max-width: 1199.98px) {
  .section--news .border-right {
    border-right: none !important;
  }
}

.footer {
  padding: 1.5625rem 0 2.1875rem;
  margin-bottom: 7.8125rem;
  height: 21.875rem;
  max-height: 21.875rem;
  position: relative;
  z-index: 2;
}
.footer:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 7.8125rem;
  transform: translateY(100%);
  background: #fff;
}
@media (max-width: 991.98px) {
  .footer:before {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .footer {
    background: #00cea5;
    height: auto;
    max-height: none;
    margin-bottom: 0;
    padding-top: 0;
  }
}

.footer__nav .nav__item {
  margin: 0;
}
@media (max-width: 991.98px) {
  .footer__nav .nav__item a {
    display: inline-block;
    line-height: 1.25;
    margin-bottom: 0.5rem;
  }
}

.footer__green {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 7.8125rem;
}
.footer__green:before {
  content: "";
  position: absolute;
  top: -1.5625rem;
  left: 0;
  width: 100%;
  height: 29.6875rem;
  background: #00cea5;
  z-index: 0;
}
@media (max-width: 991.98px) {
  .footer__green:before {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .footer__green {
    position: static;
  }
}

.footer__socials {
  position: absolute;
  top: 0;
  right: 1.25rem;
}

@media (max-width: 991.98px) {
  .footer__grey {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .footer__grey:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100%;
    background: #e2e1df;
    pointer-events: none;
  }
}

.page--detail .teaser .square--s10 {
  position: absolute;
  top: 5.625rem;
  right: 11.75rem;
}
@media (max-width: 991.98px) {
  .page--detail .teaser .square--s10 {
    position: relative;
    right: auto;
    left: 0;
    top: 0;
    height: auto;
    min-height: 28.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .page--detail .teaser .square--s10 .square__footer {
    position: static;
    width: auto;
  }
}
@media (max-width: 575.98px) {
  .page--detail .teaser .square--s10 {
    width: 19.7857142857rem;
    min-height: 19.7857142857rem;
  }
  .page--detail .teaser .square--s10 .square__title {
    font-size: 1.4285714286rem;
  }
}
@media (max-width: 991.98px) {
  .page--detail .teaser {
    margin-bottom: 3rem;
    height: auto;
  }
  .page--detail .teaser .teaser__content {
    position: relative;
    margin-top: -17.5rem;
    transform: none;
    top: 0;
    width: calc(100% - 4.375rem);
    left: 2.1875rem;
  }
}
@media (max-width: 575.98px) {
  .page--detail .teaser .teaser__content {
    margin-top: -12.5rem;
  }
}
.page--detail .fixed {
  position: absolute;
  top: 0;
  right: 0;
  width: 16.25rem;
}
@media (max-width: 991.98px) {
  .page--detail .fixed:not(.fixed--mobile) {
    display: none;
  }
}
.page--detail .fixed--mobile {
  width: 100%;
  background: #e3fae3;
  position: fixed;
  bottom: 0;
  top: auto;
  left: 0;
  right: auto;
  width: 100%;
  height: 3.9285714286rem;
  z-index: 3;
}
.page--detail .stickem-container {
  position: relative;
}
.page--detail .stickit {
  position: fixed;
  top: 4.6875rem;
  right: calc(50% - 33.75rem);
}
@media (max-width: 1199.98px) {
  .page--detail .stickit {
    right: calc(5% + 0.9375rem);
  }
}
.page--detail .stickit-end {
  top: auto;
  bottom: 0;
  position: absolute;
  right: 0;
}
.page--detail .order {
  padding: 0.625rem 1rem 1.25rem;
  background: #e3fae3;
}
.page--detail .square--green-diagonale {
  display: none;
}
@media (min-height: 575px) {
  .page--detail .fixed:not(.fixed--suspended) .square--green-diagonale {
    display: block;
  }
}
@media (min-height: 740px) {
  .page--detail .fixed--suspended .square--green-diagonale {
    display: block;
  }
}
.page--detail .section--recommendation .container {
  padding: 0;
}
.page--detail .section--testimonials .container {
  padding: 0;
}

.page--profile .profile-menu-mobile,
.page--profile .profile-menu {
  background: #f1f0ef;
  position: absolute;
  top: 0;
  right: 0;
  width: 16.25rem;
  list-style: none;
  border-bottom: 5px solid #00cea5;
  padding: 1rem;
}
.page--profile .profile-menu-mobile a:hover, .page--profile .profile-menu-mobile a .active,
.page--profile .profile-menu a:hover,
.page--profile .profile-menu a .active {
  color: #00cea5;
}
@media (max-width: 991.98px) {
  .page--profile .profile-menu-mobile {
    position: static;
    width: 100%;
    z-index: 1;
  }
  .page--profile .profile-menu-mobile .toggle {
    position: relative;
  }
  .page--profile .profile-menu-mobile .toggle:before, .page--profile .profile-menu-mobile .toggle:after {
    position: absolute;
    content: "";
    top: 50%;
    right: -1rem;
    width: 2.4285714286rem;
    height: 2.4285714286rem;
    border: 1px solid #00cea5;
    transform: translateY(-50%);
  }
  .page--profile .profile-menu-mobile .toggle:after {
    content: "↓";
    font-family: "ibm-plex-sans", sans-serif !important;
    font-weight: 700 !important;
    color: #70675e;
    font-size: 1.2857142857rem;
    text-align: center;
    line-height: 2.1428571429rem;
  }
  .page--profile .profile-menu-mobile.active .toggle {
    margin-bottom: 0.5rem;
  }
  .page--profile .profile-menu-mobile.active .toggle:after {
    content: "↑";
  }
  .page--profile .profile-menu-mobile.stickit {
    top: 3.4375rem;
    right: auto;
    left: 2.1875rem;
    width: calc(100% - 4.375rem);
  }
  .page--profile .profile-menu-mobile.stickit-end {
    top: 3.4375rem;
    bottom: auto;
    right: auto;
    left: 2.1875rem;
    position: fixed;
    width: calc(100% - 4.375rem);
  }
}
@media (max-width: 575.98px) {
  .page--profile .profile-menu-mobile.stickit {
    left: 2.5rem;
    width: calc(100% - 5rem);
  }
  .page--profile .profile-menu-mobile.stickit-end {
    left: 2.5rem;
    width: calc(100% - 5rem);
  }
}
.page--profile .stickem-container {
  position: relative;
  min-height: 50vh;
}
.page--profile .stickit {
  position: fixed;
  top: 4.6875rem;
  right: calc(50% - 33.75rem);
}
.page--profile .stickit-end {
  top: auto;
  bottom: 0;
  position: absolute;
  right: 0;
}
.page--profile .profile-header .square--s6 {
  margin-top: 8.125rem;
}
@media (max-width: 991.98px) {
  .page--profile .profile-header .square--s6 {
    margin-top: 0;
  }
}
@media (max-width: 575.98px) {
  .page--profile .profile-header .square--s6 {
    width: 7.7142857143rem;
    height: 7.7142857143rem;
  }
  .page--profile .profile-header .square--s6 p {
    font-size: 0.7142857143rem;
  }
}
@media (max-width: 575.98px) {
  .page--profile .profile-header .square--s8 {
    padding: 0.5714285714rem !important;
  }
}

.page--lectors .teaser {
  height: auto;
}
.page--lectors .teaser img {
  width: 33.5rem;
}
.page--lectors .teaser .square--1,
.page--lectors .teaser .square--2 {
  position: absolute;
}
.page--lectors .teaser .square--1 {
  bottom: 0;
}
.page--lectors .teaser .square--2 {
  bottom: -2.5rem;
  right: 0;
}
.page--lectors .lector:nth-of-type(even) {
  margin-top: 3.75rem;
}
@media (max-width: 991.98px) {
  .page--lectors .teaser {
    margin-left: -2.1875rem;
    margin-right: -2.1875rem;
    width: auto;
  }
  .page--lectors .teaser img {
    width: 100% !important;
  }
  .page--lectors .teaser .square--2 {
    right: 2.1875rem;
  }
}
@media (max-width: 575.98px) {
  .page--lectors .teaser {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .page--lectors .teaser .square--2 {
    width: 9.125rem;
    height: 9.125rem;
    right: 2.5rem;
  }
  .page--lectors .lector:nth-of-type(even) {
    margin-top: auto;
  }
}

.page--lectors-detail .square--2 {
  margin-top: 11.5625rem;
}