.tips {

	.slick-list,
	.slick-track,
	.slick-slide,
	div {
		height: 100%;
	}


}

.tips__item {
	height: 100%;
	width: 100%;

	position: relative;
}