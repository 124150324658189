.course {
	// display: flex;
	max-width: rem(630);

	@include media-breakpoint-down(md) {
		max-width: none;
	}
}

.course__figure {
	position: relative;
	flex-shrink: 0;

	.square {
		position: absolute;
		top: 0;
		left: 0;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.course__location {
}

.course__location--online {
    background: #13cda3;
    color: #fff;

		padding: rem(0) rem(5) rem(3);
}
