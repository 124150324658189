.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
  z-index: 5;

}

.header__container {
  height: rem(70);

  @include media-breakpoint-down(md) {
    height: rem(55);
  }
}

.header__logo {
  img {
    width: rem(110);

    @include media-breakpoint-down(md) {
      width: rem(98);
    }
  }
}

.header__content {
  width: 100%;
  padding-left: rem(65);

  @include media-breakpoint-down(md) {
    padding: 0 rem(75) 0 0;
  }

  @include media-breakpoint-down(xs) {
    padding-right: rem(50, 14) !important;
  }
}

.header__profile {
  &:hover,
  &:focus {
    .square {
      background-color: $secondary-color !important;
    }
  }
}

.header__nav {
  @include media-breakpoint-down(md) {

    display: flex;

    flex-direction: column;
    align-items: center;
    position: absolute;
    top: rem(54);
    left: 0;
    width: 100%;
    max-height: calc(100vh - #{rem(54)});
    overflow: auto;
    padding: 3rem 0 3rem;

    background: $text-color-inverse;

    transform: translateY(calc(-100% - #{rem(55)}));
    transition: 300ms $transitionStyle;
    opacity: 0;

    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.16);
  }

  @include media-breakpoint-down(sm) {
    height: calc(100vh - #{rem(54)});
    max-height: none;
  }

  @include media-breakpoint-down(xs) {
    justify-content: space-between;

    padding: 10rem 0;
  }
}