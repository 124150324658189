.section {
  .container > .h1:first-child,
  .container > .h2:first-child {
    margin-top: 0;
  }
}

.section--kdo-jsme {
  .square--2,
  .square--4 {
    position: relative;
    top: rem(30);
  }

  .square--5 {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @include media-breakpoint-down(md) {

    .container > .d-flex {
      margin-left: rem(-35);
      margin-right: rem(-35);
    }

    .img-kdo-jsme {
      margin-left: rem(-35);
      margin-right: rem(-35);
    }

    .bg-grey-light {

      position: relative;
      background: none;
      padding-top: 2rem;
      padding-bottom: 0;
      
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100%;
  
        background: $grey-light;
        pointer-events: none;

        z-index: -1;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .container > .d-flex {
      margin-left: rem(-35, 14);
      margin-right: rem(-35, 14);
    }

    .img-kdo-jsme {
      margin-left: rem(-35, 14);
      margin-right: rem(-35, 14);
    }

    .square--1,
    .square--2,
    .square--3,
    .square--4 {
      width: rem(94, 14);
      height: rem(94, 14);

      padding: rem(8, 14) rem(4, 14);

      span {
        display: block;
      }

      span:first-child {
        line-height: 1;
      }

      span:last-child {
        font-size: rem(10, 14);
        line-height: 1.4;
      }

    }
  }
}

.section--lectors {
  .h2 {
    
    background: #E3FAE3;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    margin-top: -4rem;
  }

  .bio-card {
    position: relative;
    top: rem(15);

    &:nth-of-type(odd) {
      top: rem(-50);
    }
  }

  @include media-breakpoint-down(md) {

    .container > .d-flex {
      margin-left: rem(-35);
      margin-right: rem(-35);
    }


  }
}

.section--login {
  .square--login {
    .square__footer {
      bottom: -1px;
      left: 0;
    }
  }
}

.section--nwl {
  position: relative;
  z-index: 2;
}

.section--recommendation {
  .square--2 {
    margin-top: rem(185);
  }

  .btn--abs {
    position: absolute;

    bottom: 0;
    right: rem(-30);

    @include media-breakpoint-down(md) {
      bottom: rem(-55);
      right: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    .square--s7 {
      width: 50%;
      height: rem(152, 14);
      padding: rem(4, 14);

      .square__footer {
        left: rem(4, 14);
        width: calc(100% - #{rem(8, 14)});
      }
    }
  }
}

.section--order {
  @include media-breakpoint-down(md) {
    margin-left: rem(-35);
    margin-right: rem(-35);

    .order {
      padding: 1rem rem(35) !important;
    }
  }

  @include media-breakpoint-down(xs) {
    margin-left: rem(-35, 14);
    margin-right: rem(-35, 14);

    .order {
      padding: 1rem rem(35, 14) !important;
    }
  }
}

.section--note {
  @include media-breakpoint-down(md) {
    .note {
      margin-left: rem(-35);
      margin-right: rem(-35);
      padding-right: rem(35);
      padding-left: calc(4rem + #{rem(35)});

      &:before {
        left: rem(35);
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .note {
      margin-left: rem(-35, 14);
      margin-right: rem(-35, 14);
      padding-right: rem(35);
      padding-left: calc(3rem + #{rem(35, 14)});

      &:before {
        left: rem(35, 14);
      }
    }
  }
}

.section--kurzy-na-klic {
  .promo {
    background-image: url(../images/kurzy-na-klic.jpg);
    background-size: cover;
  }
}

.section--news {
  .border-right {
    &:last-child {
      border-right: none !important;
    }

    @include media-breakpoint-down(lg) {
      border-right: none !important;
    }
  }
}