.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  opacity: 0;
  pointer-events: none;

  background: transparentize(#000, .75);
  z-index: 9999999999;

  transition: $transition-opacity;

  .waiting-for-load & {
    opacity: 1;
    pointer-events: all;

    .loading__icon div {
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
  }
}

.loading__icon {

  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #fff;
    border-radius: 50%;
    border-color: #fff transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

