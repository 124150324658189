.promo {

	position: relative;
	overflow: hidden;

	// gradient
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 60%;
		height: 100%;
		background: linear-gradient(to left, $primary-color 0%, transparentize($secondary-color, .4) 90%, transparentize($secondary-color, 1) 100%);
		z-index: 1;

		pointer-events: none;

		@include media-breakpoint-down(md) {
			width: 100%;
			background: linear-gradient(to right, $primary-color 0%, transparentize($secondary-color, .35) 100%);
		}
	}

}

.promo__title {
	font-size: rem(48);

	@include media-breakpoint-down(xs) {
		font-size: rem(24, 14);
	}
}