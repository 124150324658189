.tag {
	display: flex;
	align-items: center;

	max-width: rem(110);

	.square {
		@extend .text--large;
	}

	@include media-breakpoint-down(xs) {
		flex-direction: column;
		align-items: flex-start;
	}
}

// .tag--gGreen {
// 	background-image: linear-gradient(45deg, #73e673 0%, #00cea5 100%);
// }

// .tag--yellow {
// 	background: $yellow;
// }

// .tag--pink {
// 	background: $pink;
// }

// .tag--purple {
// 	background: $purple;
// }

// .tag--green {
// 	background: $primary-color;
// }

.tag--big {

	width: rem(110);

	.square {
		font-size: rem(28);
	}
}