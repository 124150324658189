.pagination {
	list-style: none;
	display: flex;

	
}

.pagination__item {
	margin: rem(10);
	
	a {
		padding: 0 !important;
		@extend .btn;
		@extend .square;
		@extend .square--s3;

		@extend .text--semi-large;
	}

	&.active {
		a {
			background-color: $primary-color !important;
			border: none !important;
		}
	}
}

.pagination__item:not(.pagination__item--prev):not(.pagination__item--next) {
	a {
		@extend .btn--transparent;
		@extend .btn--border;
	}
}

.pagination__item--prev,
.pagination__item--next {
	a {
		@extend .btn--link;

		&:after {
			font-size: rem(18) !important;
		}
	}
}

.pagination__item--prev {
	a {
		@extend .btn--link-left;
	}
}

.pagination__item--next {
	a {
		@extend .btn--link-right;
	}
}