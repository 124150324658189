.square {
	position: relative;
	padding: rem(8);
	flex-shrink: 0;

	@include media-breakpoint-down(xs) {
		padding: rem(4, 14);
	}
}

.square__footer {
	position: absolute;
	bottom: rem(15);
	left: rem(8);
	width: 100%;
}

.square--border {
	border-bottom: rem(5) solid $primary-color;
}

.square--link {
	position: relative;

	.square__link {
		position: absolute;
		bottom: 0;
		right: 0;
	}
}

a.square--link {
	&:hover {
		.square__link {
			background: $secondary-color;
		}
	}
}

.square--white {
	background: #fff;
}

.square--A,
.square--yellow {
	background: $yellow;
}

.square--gYellow,
.square--gradient-yellow {
	background-image: linear-gradient(45deg, #ffec88 0%, #ffdd7f 100%);
}

.square--orange {
	background: $orange;
}

.square--gPink,
.square--gradient-pink {
	background-image: linear-gradient(45deg, #ffccff 0%, #ff96d7 100%);
}

.square--B,
.square--pink {
	background: $pink;
}

.square--gPurple,
.square--gradient-purple {
	background-image: linear-gradient(45deg, #bcdcff 0%, #bbabff 100%);
}

.square--C,
.square--purple {
	background: $purple;
}

.square--gGreen,
.square--gradient-green {
	background-image: linear-gradient(45deg, #73e673 0%, #00cea5 100%);
}

.square--D,
.square--green {
	background: $primary-color
}

.square--green-light {
	background: #e3fae3;
}

.square--green-diagonale {
	background: url(../images/green-bg-diagonale.svg);

	background-size: cover;

	strong,
	span {
		position: absolute;
	}

	strong {
		top: rem(15);
		left: rem(16);
	}

	span {
		bottom: rem(15);
		left: rem(16);
		width: calc(100% - #{rem(16 + 16)});
	}
}

.square--disabled {
	background: $body-color;
}

.square--s1 {
	width: rem(25);
	height: rem(25);
}

.square--s2 {
	width: rem(28);
	height: rem(28);
}

.square--s3 {
	width: rem(40);
	height: rem(40);
}

.square--s4 {
	width: rem(50);
	height: rem(50);
}

.square--s5 {
	width: rem(92);
	height: rem(92);

	@include media-breakpoint-down(xs) {
		width: rem(60, 14);
		height: rem(60, 14);
	}
}

.square--s6 {
	width: rem(168);
	height: rem(168);

	@include media-breakpoint-down(xs) {
		padding: rem(8, 14);
	}
}

.square--s7 {
	width: rem(185);
	height: rem(185);

	.square__footer {
		bottom: rem(5);
	}

	@include media-breakpoint-down(md) {
		width: rem(192);
		height: rem(192);
	}

	@include media-breakpoint-down(xs) {
		width: rem(146, 14);
		height: rem(146, 14);
		padding: 1rem;
	}
}

.square--s8 {
	width: rem(260);
	height: rem(260);

	@include media-breakpoint-down(xs) {
		// body:not(.page--profile) & {
			width: rem(146, 14);
			height: rem(146, 14);
		// }
	}
}

.square--s9 {
	width: rem(277);
	height: rem(277);

	padding: rem(15);

	.square__footer {
		bottom: rem(30);
		left: rem(15);
	}
}

.square--s10 {
	width: rem(460);
	height: rem(460);

	padding: rem(20);

	.square__footer {
		left: rem(20);
		width: calc(100% - #{rem(40)});
		bottom: rem(20);
	}

	&.square--border {
		border-width: rem(8);
	}

	&.square--elastic {
		height: auto;
		min-height: rem(460);

		@include media-breakpoint-up(lg) {
			.square__title {
				margin-bottom: rem(155);
			}
		}
	}
}

img.square {
	object-fit: cover;
}