.footer {
  padding: rem(25) 0 rem(35);
  margin-bottom: rem(125);

  height: rem(350);
  max-height: rem(350);

  position: relative;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;

    bottom: 0;
    left: 0;
    width: 100%;
    height: rem(125);

    transform: translateY(100%);

    background: $text-color-inverse;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    background: $primary-color;
    height: auto;
    max-height: none;
    margin-bottom: 0;
    padding-top: 0;
  }
}

.footer__nav {
  .nav__item {
    margin: 0;

    @include media-breakpoint-down(md) {
      a {
        display: inline-block;
        line-height: 1.25;
        margin-bottom: .5rem;
      }
    }
  }
}

.footer__subnav {

}

.footer__green {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  padding-right: rem(125);
  
  &:before {
    content: '';
    position: absolute;
    top: rem(-25);
    left: 0;
    width: 100%;
    height: rem(350 + 125);
    background: $primary-color;

    z-index: 0;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    position: static;
  }
}

.footer__socials {
  position: absolute;
  top: 0;
  right: rem(20);
}

.footer__grey {
  @include media-breakpoint-down(md) {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 1rem;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: 100%;

      background: $grey;
      pointer-events: none;
    }
  }
}