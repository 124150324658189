.page--lectors {

	.teaser {
		height: auto;

		img {
			width: rem(536);
		}

		.square--1,
		.square--2 {
			position: absolute;
		}

		.square--1 {
			bottom: 0;
		}

		.square--2 {
			bottom: rem(-40);
			right: 0;
		}
	}

	.lector {
		&:nth-of-type(even) {
			margin-top: rem(60);
		}
	}

	@include media-breakpoint-down(md) {

    .teaser {
			margin-left: rem(-35);
			margin-right: rem(-35);
			width: auto;
			
			img {
				width: 100% !important;
			}

			.square--2 {
				right: rem(35);
			}
		}

	}

	@include media-breakpoint-down(xs) {
		.teaser {

			margin-left: rem(-35, 14);
      margin-right: rem(-35, 14);

			.square--2 {
				width: rem(146);
				height: rem(146);
				right: rem(35, 14);
			}
		}

		.lector {
			&:nth-of-type(even) {
				margin-top: auto;
			}
		}
	}

}

.page--lectors-detail {
	.square--2 {
		margin-top: rem(185);
	}
}