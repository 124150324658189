.teaser {
  position: relative;
  width: 100%;
  height: rem(471);
  margin-bottom: rem(150);

  z-index: 2;

  @include media-breakpoint-down(md) {
    margin-bottom: rem(350);
    .container {
      max-width: none;
      padding: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    // height: rem(280, 14);
    height: auto;
    margin-bottom: 0;
  }
}

.teaser__image {
  position: relative;

  width: rem(628);
  height: 100%;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .square {
    position: absolute;
  }

  .square--1 {
    left: 0;
    bottom: 0;
  }

  .square--2 {
    bottom: rem(92);
    left: rem(92);
  }

  .square--3 {
    bottom: rem(52);
    left: rem(92 + 40);
  }

  .square--4 {
    bottom: 0;
    right: rem(196);
  }

  @include media-breakpoint-down(md) {
    width: 100%;

    .square--4 {
      right: auto;
      left: rem(277 + 192 + 35);
    }
  }
  @include media-breakpoint-down(xs) {
    height: rem(280, 14);
  }
}

.teaser__content {
  position: relative;
  width: 100%;

  @include media-breakpoint-down(md) {
    position: absolute;
    // top: rem(114);
    // top: 42.75%;
    bottom: 0;
    transform: translateY(calc(100% - #{rem(192)}));

    .text-right {
      .btn {
        position: absolute;
        left: rem(277 + 192 + 192 + 35);
        transform: translateX(-100%);
      }
    }
  }

  @include media-breakpoint-down(xs) {
    // body:not(.page--detail) & {
      position: relative;
      transform: none;
  
      margin-top: rem(-176, 14);
      bottom: auto;
      left: rem(35, 14);
  
      width: calc(100% - #{rem(70, 14)});
  
      .text-right {
        .btn {
          position: relative;
          left: auto;
  
          transform: none;
        }
      }
    // }
  }
}

.teaser__courses {
  position: relative;
  height: rem(185 + 185);

  .square {
    position: absolute;
  }

  .square--1 {
    top: 0;
    right: rem(185 + 185);
  }

  .square--2 {
    top: 0;
    right: rem(185);
  }

  .square--3 {
    top: rem(185);
    right: 0;
  }

  @include media-breakpoint-down(md) {

    width: 100%;

    .square--1 {
      left: rem(35);
    }

    .square--2 {
      left: rem(277 + 35);
      right: auto;
    }

    .square--3 {
      left: rem(277 + 192 + 35);
      right: auto;
      top: rem(185 + 6);
    }
  }

  @include media-breakpoint-down(xs) {
    display: flex;
    flex-wrap: wrap;

    height: auto;

    > .square {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
    }

    .square--1 {
      width: rem(276, 14);
      height: rem(276, 14);
    }

    .square--2,
    .square--3 {
      width: rem(152, 14);
      height: rem(152, 14);

      padding: rem(4, 14);

      .square__footer {
        left: rem(4, 14);
      }
    }
  }
}