.popup {

}

.popup--watchNow {
  position: fixed;
  bottom: rem(20);
  left: rem(20);

  background: $primary-color;
  padding: $grid-gutter-width / 2;

  box-shadow: 0 0 5px 0 transparentize(#000000, .75);

  z-index: 10;

  transform: translateY(150%);
  opacity: 0;
  pointer-events: none;
  transition: $transition;

  &.active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}